import BrowserHistoryState from '@/store/BrowserHistoryState';
import URLWrapper from '@/store/URLWrapper';

export default class URLGridParameterConfig {
    applyGridParameters(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        this.applyDimensions(browserHistoryState, urlWrapper);
        this.applyMetrics(browserHistoryState, urlWrapper);
        this.applySorting(browserHistoryState, urlWrapper);
        this.applyPinning(browserHistoryState, urlWrapper);
        this.applyFilters(browserHistoryState, urlWrapper);
        this.applyChartLines(browserHistoryState, urlWrapper);
    }

    private applyDimensions(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'dimensions';
        urlWrapper.deleteURLParameter(parameterName);
        browserHistoryState.dimensions.forEach((dimension) => {
            urlWrapper.appendURLParameter(parameterName, dimension);
        });
    }

    private applyMetrics(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'metrics';
        urlWrapper.deleteURLParameter(parameterName);
        browserHistoryState.metrics.forEach((metric) => {
            urlWrapper.appendURLParameter(parameterName, metric);
        });
    }

    private applySorting(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'sorting';
        urlWrapper.deleteURLParameter(parameterName);
        browserHistoryState.sorting.forEach((sorting) => {
            urlWrapper.appendURLParameter(parameterName, JSON.stringify(sorting));
        });
    }

    private applyPinning(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'pinning';
        urlWrapper.deleteURLParameter(parameterName);
        browserHistoryState.pinning.forEach((pinning) => {
            urlWrapper.appendURLParameter(parameterName, JSON.stringify(pinning));
        });
    }

    private applyFilters(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'filters';
        urlWrapper.deleteURLParameter(parameterName);
        if (browserHistoryState.filters === undefined) {
            return;
        }
        urlWrapper.appendURLParameter(parameterName, JSON.stringify(browserHistoryState.filters));
    }

    private applyChartLines(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'chartLines';
        urlWrapper.deleteURLParameter(parameterName);
        if (!browserHistoryState.chartLines) {
            return;
        }
        browserHistoryState.chartLines.forEach((chartLine) => {
            const color = chartLine.color ? chartLine.color.hex : null;
            urlWrapper.appendURLParameter(
                parameterName,
                JSON.stringify({
                    value: chartLine.value,
                    description: chartLine.description,
                    color,
                }),
            );
        });
    }
}
