import { ACTION_NAME } from '@ads/iam-library/dist/src/types';
import { RequestService } from '@ads/iam-library';
import { IDashboard } from '@/dashboards/IDashboard';
import { API_URL_DASHBOARDS, isDevelopmentEnv } from '@/config';
import dynamicDashboardsLocal from '@/../.local/dynamicDashboards.json';

export default class DynamicDashboardService {
    load(): Promise<IDashboard[]> {
        const url = 'dashboards';
        const options = {
            domain: 'dashboards',
            resource: 'dashboards',
            action: ACTION_NAME.READ,
        };
        const requestService = new RequestService({ baseUrl: API_URL_DASHBOARDS });

        if (isDevelopmentEnv) {
            return Promise.resolve(dynamicDashboardsLocal);
        }

        return requestService.get(url, options);
    }
}
