import { Expose, Type } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';
import IowResponseRows from '@/components/grid/api/iow/response/IowResponseRows';
import IowResponseTotal from '@/components/grid/api/iow/response/IowResponseTotal';
import IowResponseAdditions from '@/components/grid/api/iow/response/IowResponseAdditions';

export default class IowResponse {
    @Expose()
    status: string;

    @Expose()
    @Type(() => IowResponseRows)
    rows: IowResponseRows[];

    @Expose()
    @Type(() => IowResponseTotal)
    total: IowResponseTotal;

    @Expose()
    @Type(() => IowResponseAdditions)
    others?: IowResponseAdditions;
}
