import { CustomDimensionFilterModel, FilterCondition } from '@/components/grid/CustomDimensionFilterModel';
import IMultiGridFilter from '@/components/grid/api/iow/request/IMultiGridFilter';

type UnknownFiltersContainer = {
    [key: string]: {
        filterType: string;
        filterModels: (CustomDimensionFilterModel | FilterCondition | IMultiGridFilter)[];
    };
};

type CustomFiltersContainer = {
    [key: string]: {
        filterType: string;
        filterModels: (CustomDimensionFilterModel | FilterCondition)[];
    };
};

export default class CustomFilterTransformer {
    transformFilter(filter: UnknownFiltersContainer): CustomFiltersContainer {
        const transformedFilter: CustomFiltersContainer = {};
        Object.keys(filter).forEach((key) => {
            transformedFilter[key] = {
                filterType: filter[key].filterType,
                filterModels: this.transformFilterModels(filter[key].filterModels),
            };
        });
        return transformedFilter;
    }

    private transformFilterModels(filterModels: any[]): (CustomDimensionFilterModel | FilterCondition)[] {
        const transformedFilterModels = [...filterModels];
        if (this.shouldTransformFilterModel(transformedFilterModels[0])) {
            transformedFilterModels[0] = this.transformGridFilterModel(transformedFilterModels[0]);
        }
        return transformedFilterModels;
    }

    private shouldTransformFilterModel(filterModel): boolean {
        return !!filterModel && filterModel.filterType === 'text' && !filterModel.conditions;
    }

    private transformGridFilterModel(filterModel: any): CustomDimensionFilterModel {
        if (this.hasTwoConditions(filterModel)) {
            return {
                filterType: filterModel.filterType,
                operator: filterModel.operator,
                conditions: [filterModel.condition1, filterModel.condition2],
            };
        }
        return {
            filterType: filterModel.filterType,
            operator: 'OR',
            conditions: [filterModel],
        };
    }

    private hasTwoConditions(filterModel: any): boolean {
        return !!filterModel.condition1 && !!filterModel.condition2;
    }
}
