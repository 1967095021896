import { format, subDays } from 'date-fns';
import BrowserHistoryState from '@/store/BrowserHistoryState';
import URLWrapper from '@/store/URLWrapper';

export default class URLGraphParameterConfig {
    applyGraphParameters(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        this.applyFirstMetric(browserHistoryState, urlWrapper);
        this.applySecondMetric(browserHistoryState, urlWrapper);
        this.applyGranularity(browserHistoryState, urlWrapper);
        this.applyTimeShift(browserHistoryState, urlWrapper);
        this.applyTimezone(browserHistoryState, urlWrapper);
        this.applyDateRangeSelection(browserHistoryState, urlWrapper);
        if (!(browserHistoryState.dateRangeSelection === undefined || browserHistoryState.dateRangeSelection === null)) {
            urlWrapper.deleteURLParameter('dateFrom');
            urlWrapper.deleteURLParameter('dateTo');
            return;
        }

        if (!this.hasDefaultDateFrom(browserHistoryState) || !this.hasDefaultDateTo(browserHistoryState)) {
            this.applyDateFrom(browserHistoryState, urlWrapper);
            this.applyDateTo(browserHistoryState, urlWrapper);
        }
    }

    private applyFirstMetric(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'firstMetric';
        urlWrapper.deleteURLParameter(parameterName);
        if (!browserHistoryState.firstMetric) {
            return;
        }
        urlWrapper.appendURLParameter(parameterName, browserHistoryState.firstMetric);
    }

    private applySecondMetric(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'secondMetric';
        urlWrapper.deleteURLParameter(parameterName);
        if (!browserHistoryState.secondMetric) {
            return;
        }
        urlWrapper.appendURLParameter(parameterName, browserHistoryState.secondMetric);
    }

    private applyGranularity(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'granularity';
        urlWrapper.deleteURLParameter(parameterName);
        if (!browserHistoryState.granularity) {
            return;
        }
        urlWrapper.appendURLParameter(parameterName, browserHistoryState.granularity);
    }

    private applyTimeShift(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'timeShift';
        urlWrapper.deleteURLParameter(parameterName);
        if (!browserHistoryState.timeShift) {
            return;
        }
        urlWrapper.appendURLParameter(parameterName, browserHistoryState.timeShift);
    }

    private applyDateFrom(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'dateFrom';
        urlWrapper.deleteURLParameter(parameterName);
        if (!browserHistoryState.dateFrom) {
            return;
        }
        urlWrapper.appendURLParameter(parameterName, browserHistoryState.dateFrom);
    }

    private applyDateTo(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'dateTo';
        urlWrapper.deleteURLParameter(parameterName);
        if (!browserHistoryState.dateTo) {
            return;
        }
        urlWrapper.appendURLParameter(parameterName, browserHistoryState.dateTo);
    }

    private applyTimezone(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'timezone';
        urlWrapper.deleteURLParameter(parameterName);
        if (browserHistoryState.timezone === undefined) {
            return;
        }
        urlWrapper.appendURLParameter(parameterName, browserHistoryState.timezone);
    }

    private applyDateRangeSelection(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const parameterName = 'dateRangeSelection';
        urlWrapper.deleteURLParameter(parameterName);
        if (browserHistoryState.dateRangeSelection === undefined || browserHistoryState.dateRangeSelection === null) {
            return;
        }
        urlWrapper.appendURLParameter(parameterName, browserHistoryState.dateRangeSelection);
    }

    private hasDefaultDateFrom(browserHistoryState: BrowserHistoryState): boolean {
        if (!browserHistoryState.dateFrom) {
            return true;
        }
        const currentDate = new Date();
        const defaultStartDate = format(subDays(currentDate, 14), 'yyyy-MM-dd');
        return browserHistoryState.dateFrom === defaultStartDate;
    }

    private hasDefaultDateTo(browserHistoryState: BrowserHistoryState): boolean {
        if (!browserHistoryState.dateTo) {
            return true;
        }
        const currentDate = new Date();
        const defaultEndDate = format(subDays(currentDate, 1), 'yyyy-MM-dd');
        return browserHistoryState.dateTo === defaultEndDate;
    }
}
