import BrowserHistoryState from '@/store/BrowserHistoryState';
import URLParameterConfig from '@/store/URLParameterConfig';

export default class URLWrapper {
    private url: URL;

    private readonly urlParameterConfig: URLParameterConfig;

    constructor(urlParameterConfig: URLParameterConfig) {
        this.urlParameterConfig = urlParameterConfig;
        this.url = new URL(window.location.toString());
    }

    getURLString(): string {
        return this.url.toString();
    }

    hasParameters(): boolean {
        return Boolean(Array.from(this.url.searchParams.keys()).length);
    }

    updateGridSearchParameters(browserHistoryState: BrowserHistoryState): void {
        this.urlParameterConfig.applyGridParameters(browserHistoryState, this);
    }

    updateGraphSearchParameters(browserHistoryState: BrowserHistoryState): void {
        this.urlParameterConfig.applyGraphParameters(browserHistoryState, this);
    }

    appendURLParameter(parameterName: string, value: string): void {
        this.url.searchParams.append(parameterName, value);
    }

    deleteURLParameter(parameterName: string): void {
        this.url.searchParams.delete(parameterName);
    }
}
