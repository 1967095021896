import { ColDef, GridOptions } from 'ag-grid-community';
import IRequestParameterTransformer from '@/components/grid/api/IRequestParameterTransformer';
import IApiRequest from '@/components/grid/api/IApiRequest';
import IResponseTransformer from '@/components/grid/api/IResponseTransformer';
import IApi from '@/components/grid/api/IApi';
import SyncApi from '@/components/grid/api/SyncApi';
import IGlobalFilter from '@/components/grid/IGlobalFilter';
import gridEvents from '@/components/grid/gridEvents';

export default abstract class ComponentFactory {
    abstract createApiRequest(): IApiRequest;

    abstract createRequestParameterTransformer(): IRequestParameterTransformer;

    abstract createResponseTransformer(): IResponseTransformer;

    abstract createDimensions(): ColDef[];

    abstract createMetrics(): ColDef[];

    createGridEvents(): GridOptions {
        return gridEvents;
    }

    createSyncApi(globalFilter: IGlobalFilter): IApi {
        const apiRequest = this.createApiRequest();
        const requestTransformer = this.createRequestParameterTransformer();
        const responseTransformer = this.createResponseTransformer();

        return new SyncApi(apiRequest, requestTransformer, responseTransformer, globalFilter);
    }
}
