import { plainToClass } from 'class-transformer';
import IowResponse from '@/components/grid/api/iow/response/IowResponse';
import IResponseTransformer from '@/components/grid/api/IResponseTransformer';
import IowRequestParameters from '@/components/grid/api/iow/request/IowRequestParameters';
import IowRowsTransformer from '@/components/grid/api/iow/response/IowRowsTransformer';
import IApiResult from '@/components/grid/api/IApiResult';
import IowTotalRowsTransformer from '@/components/grid/api/iow/response/IowTotalRowsTransformer';

export default class IowResponseTransformer implements IResponseTransformer {
    transform(response, request: IowRequestParameters): IApiResult {
        const iowResponse: IowResponse = plainToClass(IowResponse, response);
        const iowRowsTransformer = new IowRowsTransformer();
        const iowTotalRowsTransformer = new IowTotalRowsTransformer(iowRowsTransformer, request);
        const rows = iowRowsTransformer.transform(iowResponse.rows, request.splitBy);
        const totalRows = iowTotalRowsTransformer.transform(iowResponse.total);
        return {
            totalRows,
            rows: {
                rowData: rows,
                rowCount: iowResponse.total.recordsFound,
            },
        };
    }
}
