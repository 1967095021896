import IMultiGridFilter from '@/components/grid/api/iow/request/IMultiGridFilter';
import IowFilterGenerator from '@/components/grid/api/iow/request/IowFilterGenerator';
import { CustomDimensionFilterModel, FilterCondition } from '@/components/grid/CustomDimensionFilterModel';
import IowDimensionFilter from '@/components/grid/api/iow/request/IowDimensionFilter';

export default class IowConditionsFilterTransformer {
    private readonly iowFilterGenerator: IowFilterGenerator;

    constructor(iowFilterGenerator: IowFilterGenerator) {
        this.iowFilterGenerator = iowFilterGenerator;
    }

    transform(columnKey: string, conditions: IMultiGridFilter | CustomDimensionFilterModel): IowDimensionFilter[] {
        if (this.isMultiGridFilter(conditions)) {
            return this.transformMultiGridFilter(columnKey, conditions as IMultiGridFilter);
        }
        return this.transformCustomFilterModel(columnKey, conditions as CustomDimensionFilterModel);
    }

    private transformMultiGridFilter(columnKey: string, multiGridFilter: IMultiGridFilter): IowDimensionFilter[] {
        if (multiGridFilter.operator === 'AND' || !this.hasSamePredicate(multiGridFilter)) {
            return [
                this.iowFilterGenerator.generate(columnKey, multiGridFilter.condition1.type, [multiGridFilter.condition1.filter]),
                this.iowFilterGenerator.generate(columnKey, multiGridFilter.condition2.type, [multiGridFilter.condition2.filter]),
            ];
        }
        const filterValue = [multiGridFilter.condition1.filter, multiGridFilter.condition2.filter];
        const filter = this.iowFilterGenerator.generate(columnKey, multiGridFilter.condition1.type, filterValue);

        return [filter];
    }

    private transformCustomFilterModel(
        columnKey: string,
        customDimensionFilter: CustomDimensionFilterModel,
    ): IowDimensionFilter[] {
        const groupedConditions = this.groupConditionsByPredicate(customDimensionFilter.conditions);
        if (customDimensionFilter.operator === 'AND') {
            return customDimensionFilter.conditions
                .filter((condition) => condition.filter.length > 0)
                .map((condition) => this.iowFilterGenerator.generate(columnKey, condition.type, [condition.filter]));
        }

        const mappedFilters: IowDimensionFilter[] = [];
        groupedConditions.forEach((conditions, predicate) => {
            const filteredConditions = conditions.filter((condition) => condition.filter.length > 0);
            if (filteredConditions.length > 0) {
                mappedFilters.push(this.transformGroupedConditionsToIowDimensionFilter(columnKey, predicate, filteredConditions));
            }
        });
        return mappedFilters;
    }

    private isMultiGridFilter(conditions: IMultiGridFilter | CustomDimensionFilterModel): boolean {
        // @ts-ignore
        return Boolean(conditions.condition1) && Boolean(conditions.condition2);
    }

    private hasSamePredicate(multiGridFilter: IMultiGridFilter): boolean {
        return multiGridFilter.condition1.type === multiGridFilter.condition2.type;
    }

    private groupConditionsByPredicate(conditions: FilterCondition[]): Map<string, FilterCondition[]> {
        const map = new Map<string, FilterCondition[]>();
        conditions.forEach((condition) => {
            if (map.has(condition.type)) {
                map.get(condition.type).push(condition);
            } else {
                map.set(condition.type, [condition]);
            }
        });
        return map;
    }

    private transformGroupedConditionsToIowDimensionFilter(
        columnKey: string,
        predicate: string,
        conditions: FilterCondition[],
    ): IowDimensionFilter {
        return this.iowFilterGenerator.generate(
            columnKey,
            predicate,
            conditions.map((condition) => condition.filter).filter((filterValue) => filterValue.length > 0),
        );
    }
}
