import * as am4charts from '@amcharts/amcharts4/charts';
import { LineSeries } from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { differenceInDays, parse } from 'date-fns';
import IGranularityAction from '@/components/graph/manipulation/granularity/IGranularityAction';
import VueStoreFilter from '@/components/grid/VueStoreFilter';

export default class LineSeriesAction implements IGranularityAction {
    private readonly modifier;

    private readonly lineSeries: LineSeries;

    constructor(lineSeries: LineSeries) {
        this.lineSeries = lineSeries;
        this.modifier = {
            granularity_hour: () => this.granularityHour(),
        };
    }

    runOn(granularity: string): void {
        if (this.modifier[granularity]) {
            this.modifier[granularity]();
            return;
        }
        this.granularityDefault();
    }

    granularityDefault(): void {
        const bullet = this.lineSeries.bullets.push(new am4charts.CircleBullet());
        bullet.circle.stroke = this.lineSeries.stroke;
        bullet.circle.fill = am4core.color('#fff');
        bullet.circle.radius = 3;
        bullet.circle.strokeWidth = 2;
        bullet.circle.opacity = this.lineSeries.opacity;
        const bulletHover = bullet.states.create('hover');
        bulletHover.properties.scale = 2;
    }

    granularityHour(): void {
        const vueStoreFilter = new VueStoreFilter();
        const startDate = parse(vueStoreFilter.getStartDate(), 'yyyy-MM-dd', new Date());
        const endDate = parse(vueStoreFilter.getEndDate(), 'yyyy-MM-dd', new Date());
        const currentDay = 1;
        const dayDifference = differenceInDays(endDate, startDate) + currentDay;
        if (dayDifference <= 4) {
            this.granularityDefault();
        }
    }
}
