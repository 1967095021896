import 'es6-shim';
import 'reflect-metadata';
import { Expose } from 'class-transformer';

export default class TimeShiftConfig {
    @Expose()
    unit: string;

    @Expose()
    days: number;
}
