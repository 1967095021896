import { Exclude, Expose, Type } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';
import IowDimensionFilter from '@/components/grid/api/iow/request/IowDimensionFilter';
import Order from '@/components/grid/api/iow/request/Order';
import IowMetricFilter from '@/components/grid/api/iow/request/IowMetricFilter';

export default class IowRequestParameters {
    @Expose({ name: 'start_date' })
    startDate: string;

    @Expose({ name: 'end_date' })
    endDate: string;

    @Expose({ name: 'split_by' })
    splitBy: string[] = [];

    @Expose()
    granularity = 'granularity_day';

    @Expose()
    timezone?: number;

    @Type(() => Order)
    @Expose({ name: 'order_by' })
    orderBy?: Order[];

    @Type(() => IowDimensionFilter)
    @Expose()
    filters?: IowDimensionFilter[] = [];

    @Expose({ name: 'filter_template' })
    filterTemplate?: string;

    @Type(() => IowMetricFilter)
    @Expose({ name: 'data_filters' })
    dataFilters?: IowMetricFilter[] = [];

    @Expose({ name: 'data_filter_template' })
    dataFilterTemplate?: string = '';

    @Expose()
    limit?: number;

    @Expose({ name: 'chart_lines' })
    chartLines: string[] = ['total'];

    @Expose({ name: 'data_field' })
    dataField: string;

    @Exclude()
    // eslint-disable-next-line @typescript-eslint/naming-convention
    timeShift = false;
}
