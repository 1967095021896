import TimeShiftConfig from '@/components/graph/TimeShiftConfig';

const granularityTimeShiftMapping = {
    granularity_hour: {
        name: 'hours',
        days: (timeShift) => Math.ceil(timeShift / 24),
    },
    granularity_day: {
        name: 'days',
        days: (timeShift) => timeShift,
    },
    granularity_week: {
        name: 'weeks',
        days: (timeShift) => timeShift * 7,
    },
    granularity_month: {
        name: 'months',
        days: (timeShift) => timeShift * 30,
    },
    granularity_quarter: {
        name: 'quarters',
        days: (timeShift) => timeShift * 90,
    },
    granularity_year: {
        name: 'years',
        days: (timeShift) => timeShift * 365,
    },
};

export default class TimeShiftConfigFactory {
    create(timeShift: number, granularity: string): TimeShiftConfig {
        const timeShiftConfig = new TimeShiftConfig();
        const granularityTimeShiftItem = granularityTimeShiftMapping[granularity];
        if (!granularityTimeShiftItem) {
            timeShiftConfig.unit = 'days';
            timeShiftConfig.days = timeShift;
            return timeShiftConfig;
        }

        timeShiftConfig.unit = granularityTimeShiftItem.name;
        timeShiftConfig.days = granularityTimeShiftItem.days(timeShift);
        return timeShiftConfig;
    }
}
