import VueRouter, { RouteRecordPublic } from 'vue-router';
import { IDashboard } from '@/dashboards/IDashboard';
import IStaticDashboardRoute from '@/routes/types/IStaticDashboardRoute';
import IDynamicDashboardRoute from '@/routes/types/IDynamicDashboardRoute';

type RouterRoute = RouteRecordPublic;

export default class RoutingService {
    private readonly router: VueRouter;

    private readonly dynamicDashboardPathPrefix: string = '/dashboards';

    constructor(router: VueRouter) {
        this.router = router;
    }

    static createStaticDashboardRoutes(dashboards: IDashboard[]): IStaticDashboardRoute[] {
        return dashboards.map((config) => ({
            name: config.resourceType,
            path: `/${config.routeName}`,
        }));
    }

    private getCurrentRoutes(): RouterRoute[] {
        return this.router.getRoutes();
    }

    private isDashboardRouteInCurrentRoutes(currentRoutes: RouterRoute[], config: IDashboard): boolean {
        if (!currentRoutes || !currentRoutes.length) {
            return false;
        }
        return currentRoutes.some((route) => route && route.path === this.addLeadingSlashToPath(config.routeName));
    }

    async addDynamicDashboardRoutes(dashboards: IDashboard[]): Promise<void> {
        if (!dashboards.length) {
            return;
        }

        const currentRoutes: RouterRoute[] = this.getCurrentRoutes();

        dashboards
            .filter((config) => !this.isDashboardRouteInCurrentRoutes(currentRoutes, config))
            .forEach((dashboard) => {
                const route: IDynamicDashboardRoute = {
                    name: dashboard.routeName,
                    path: this.dynamicDashboardPathPrefix + this.addLeadingSlashToPath(dashboard.routeName),
                    props: { resourceType: dashboard.resourceType },
                };

                this.router.addRoute(route);
            });
    }

    private addLeadingSlashToPath(path: string): string {
        return path.startsWith('/') ? path : `/${path}`;
    }
}
