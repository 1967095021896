import { Column, ColumnApi, GridApi } from 'ag-grid-community';
import ColumnDefinitionService from '@/components/grid/ColumnDefinitionService';
import defaultMetrics from '@/components/grid/api/iow/defaultMetrics';
import defaultDimensions from '@/components/grid/api/iow/defaultDimensions';

export default class ColumnWrapper {
    private readonly column: Column;

    private readonly columnApi: ColumnApi;

    private readonly columnDefinitionService: ColumnDefinitionService;

    private readonly api: GridApi;

    constructor(column: Column, columnDefinitionService: ColumnDefinitionService, api: GridApi, columnApi: ColumnApi) {
        this.columnDefinitionService = columnDefinitionService;
        this.columnApi = columnApi;
        this.column = column;
        this.api = api;
    }

    isMoving(): boolean {
        return this.column.isMoving();
    }

    changeVisibility(visibility: boolean): void {
        this.column.getColDef().hide = !visibility;
        this.columnApi.setColumnVisible(this.column.getColId(), visibility);
    }

    isLastRemainingDimension(): boolean {
        if (this.isDimension()) {
            return !this.columnDefinitionService.getActiveDimensions(this.columnApi).length;
        }
        return false;
    }

    wasVisibilityChanged() {
        return this.getDefinitionVisibility() !== this.column.isVisible();
    }

    isMetric() {
        return defaultMetrics.some((metric) => metric.field === this.column.getColId());
    }

    isDimension() {
        return defaultDimensions.some((dimension) => dimension.field === this.column.getColId());
    }

    isVisible() {
        return this.column.isVisible();
    }

    isStale() {
        const rowNode = this.api.getRowNode('1');
        if (!rowNode) {
            return true;
        }

        const firstRow = rowNode.data[this.column.getColId()];
        const isColumnDataAvailable = firstRow !== undefined;
        return this.isDimension() || (this.column.isVisible() && !isColumnDataAvailable);
    }

    getColId() {
        return this.column.getColId();
    }

    getDefinitionVisibility() {
        return !this.column.getColDef().hide;
    }
}
