import IowFilterModelTransformer from '@/components/grid/api/iow/request/IowFilterModelTransformer';

export default class IowMultiFilterModelTransformer {
    private readonly iowFilterModelTransformer: IowFilterModelTransformer;

    constructor(iowFilterModelTransformer: IowFilterModelTransformer) {
        this.iowFilterModelTransformer = iowFilterModelTransformer;
    }

    transform(columnKey: string, gridFilterModels: any) {
        return gridFilterModels.flatMap((filter) => this.iowFilterModelTransformer.transform(columnKey, filter));
    }
}
