import { Expose } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';
import ChartLine from '@/components/graph/ChartLine';
import ResponseCollection from '@/components/graph/api/ResponseCollection';

export default class GraphParameters {
    @Expose()
    activeDimensions: string[];

    @Expose()
    metrics: string[];

    @Expose()
    filterModel: any;

    @Expose()
    chartLines: ChartLine[] = [];

    @Expose()
    responseCollection: ResponseCollection;

    @Expose()
    granularity: string;

    @Expose()
    timeShift: number = null;

    @Expose()
    originalTimeShift: number = null;

    calculateTimeShiftOffsetForHours(index: number): number {
        const daysShifted = Math.ceil(this.originalTimeShift / 24);
        return daysShifted * 24 - this.originalTimeShift + index;
    }
}
