import { Expose } from 'class-transformer';
import IowResponse from '@/components/graph/api/iow/response/IowResponse';
import 'es6-shim';
import 'reflect-metadata';
import IowResponseRow from '@/components/graph/api/iow/response/IowResponseRow';

export default class SingleIowResponse {
    @Expose()
    response: IowResponse;

    @Expose()
    metric: string;

    @Expose()
    isTimeShift: boolean;

    getRowAtIndex(index: number): IowResponseRow {
        return this.response.rows[index];
    }
}
