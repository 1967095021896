import { ColDef } from 'ag-grid-community';
import FormatterFactory from '@/components/grid/FormatterFactory';

const formatterFactory = new FormatterFactory();
const percentFormatter = formatterFactory.createPercentFormatter();
const currencyFormatter = formatterFactory.createCurrencyFormatter();
const unknownCurrencyFormatter = formatterFactory.createUnknownCurrencyFormatter();
const metrics: ColDef[] = [
    {
        headerName: 'Media Spend (USD)',
        field: 'payout.actual_adv_usd',
        valueFormatter: currencyFormatter,
    },
    {
        headerName: 'Media Spend (Local)',
        field: 'payout.actual_adv_alternative_currency',
        valueFormatter: unknownCurrencyFormatter,
        hide: true,
    },
    {
        headerName: 'Impressions',
        field: 'imps',
    },
    {
        headerName: 'Clicks',
        field: 'clicks',
    },
    {
        headerName: 'CTR (%)',
        field: 'ctr',
        valueFormatter: percentFormatter,
    },
    {
        headerName: 'eCPM (USD)',
        field: 'adv_ecpm',
        valueFormatter: currencyFormatter,
    },
    {
        headerName: 'eCPM (Local)',
        field: 'adv_ecpm_alt_currency',
        valueFormatter: unknownCurrencyFormatter,
        hide: true,
    },
    {
        headerName: 'eCPA Deposit (USD)',
        field: 'custom_column_20793',
        valueFormatter: currencyFormatter,
        hide: true,
    },
    {
        headerName: 'eCPA Deposit (Local)',
        field: 'custom_column_20789',
        valueFormatter: unknownCurrencyFormatter,
        hide: true,
    },
    {
        headerName: 'eCPA FTD (USD)',
        field: 'custom_column_20121',
        valueFormatter: currencyFormatter,
        hide: true,
    },
    {
        headerName: 'eCPA FTD (Local)',
        field: 'custom_column_25985',
        valueFormatter: unknownCurrencyFormatter,
        hide: true,
    },
    {
        headerName: 'eCPA Reg finished (USD)',
        field: 'custom_column_20123',
        valueFormatter: currencyFormatter,
        hide: true,
    },
    {
        headerName: 'eCPA Reg finished (Local)',
        field: 'custom_column_25987',
        valueFormatter: unknownCurrencyFormatter,
        hide: true,
    },
    {
        headerName: 'FTDs',
        field: 'pixel.type.ftd',
    },
    {
        headerName: 'Reg started',
        field: 'pixel.type.reg_started',
    },
    {
        headerName: 'Reg finished',
        field: 'pixel.type.reg_finished',
    },
    {
        headerName: 'Page Views',
        field: 'pixel.type.ros',
    },
    {
        headerName: 'Logins',
        field: 'pixel.type.login',
    },
    {
        headerName: 'Deposits',
        field: 'pixel.type.deposit',
    },
    {
        headerName: 'Bet Placed',
        field: 'pixel.type.bet_placed',
        hide: true,
    },
    {
        headerName: 'Matches',
        field: 'pixel.type.event',
    },
    {
        headerName: 'App Installs',
        field: 'pixel.type.install',
        hide: true,
    },
    {
        headerName: 'Other Conversions',
        field: 'pixel.type.other',
    },
    {
        headerName: 'Viewability (%)',
        field: 'custom_column_25823',
        valueFormatter: percentFormatter,
    },
    {
        headerName: 'Unique User (Cookie-based)',
        field: 'audience',
        hide: true,
    },
    {
        headerName: 'Unique User (Cookie-less)',
        field: 'calculated_puuid',
        hide: true,
    },
    {
        headerName: 'Bids',
        field: 'bids',
        hide: true,
    },
    {
        headerName: 'Nobids',
        field: 'nobids',
        hide: true,
    },
    {
        headerName: 'Vast Start',
        field: 'vast_starts',
        hide: true,
    },
    {
        headerName: 'Vast Viewed (25%)',
        field: 'vast_25',
        hide: true,
    },
    {
        headerName: 'Vast Viewed (50%)',
        field: 'vast_50',
        hide: true,
    },
    {
        headerName: 'Vast Viewed (75%)',
        field: 'vast_75',
        hide: true,
    },
    {
        headerName: 'Vast Complete View',
        field: 'vast_completes',
        hide: true,
    },
    {
        headerName: 'Post Click Conversions',
        field: 'pcc_amount',
        hide: true,
    },
    {
        headerName: 'Post View Conversions',
        field: 'pvc_amount',
        hide: true,
    },
    {
        headerName: 'Third Party FTD',
        field: 'custom_column_38427',
        hide: true,
    },
    {
        headerName: 'Third Party Reg Start',
        field: 'custom_column_56314',
        hide: true,
    },
    {
        headerName: 'Third Party Reg Finish',
        field: 'custom_column_56318',
        hide: true,
    },
    {
        headerName: 'Third Party Bet Placed',
        field: 'custom_column_56368',
        hide: true,
    },
    {
        headerName: 'eCPA (Third Party FTD) (Local)',
        field: 'custom_column_56446',
        hide: true,
    },
    {
        headerName: 'eCPA (Third Party FTD) (USD)',
        field: 'custom_column_56447',
        hide: true,
    },
    {
        headerName: 'eCPA (Third Party Reg Fin) (Local)',
        field: 'custom_column_56448',
        hide: true,
    },
    {
        headerName: 'eCPA (Third Party Reg Fin) (USD)',
        field: 'custom_column_56449',
        hide: true,
    },
    {
        headerName: 'eCPA (Third Party Reg Start) (Local)',
        field: 'custom_column_56450',
        hide: true,
    },
    {
        headerName: 'eCPA (Third Party Reg Start) (USD)',
        field: 'custom_column_56451',
        hide: true,
    },
    {
        headerName: 'eCPA (Third Party Bet Placed) (Local)',
        field: 'custom_column_56452',
        hide: true,
    },
    {
        headerName: 'eCPA (Third Party Bet Placed) (USD)',
        field: 'custom_column_56453',
        hide: true,
    },
    {
        headerName: 'eCPC (USD)',
        field: 'adv_ecpc',
        hide: true,
    },
    {
        headerName: 'eCPC (Local)',
        field: 'custom_column_23353',
        hide: true,
    },
    {
        headerName: 'DOOH audience size',
        field: 'supply.dooh_audience',
        hide: true,
    },
    {
        headerName: 'DOOH eCPM',
        field: 'dooh_adv_ecpm',
        hide: true,
    },
];

export default metrics.reduce((accumulator, metric: ColDef) => {
    metric.valueFormatter = metric.valueFormatter || formatterFactory.createDecimalFormatter();
    metric.cellStyle = metric.cellStyle || { textAlign: 'right' };
    metric.headerClass = 'metrics';
    metric.menuTabs = ['generalMenuTab', 'filterMenuTab'];
    metric.filter = 'agMultiColumnFilter';
    metric.filterParams = {
        filters: [
            {
                filter: 'agNumberColumnFilter',
                filterParams: {
                    filterOptions: ['inRange'],
                    defaultOption: 'inRange',
                    maxNumConditions: 1,
                    buttons: ['reset', 'apply'],
                },
            },
        ],
    };
    accumulator.push(metric);
    return accumulator;
}, []);
