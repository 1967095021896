import { Expose } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';

enum DIRECTION {
    asc = 'asc',
    desc = 'desc',
}

export default class Order {
    @Expose()
    name: string;

    @Expose()
    direction: DIRECTION;
}

export { DIRECTION };
