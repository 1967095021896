import StaticDashboardService from '@/dashboards/StaticDashboardService';
import IStaticDashboardRoute from '@/routes/types/IStaticDashboardRoute';
import RoutingService from '@/routes/RoutingService';
import rootRoute from '@/routes/rootRoute';

const staticDashboardService = new StaticDashboardService();
const staticDashboards = staticDashboardService.get();
const staticRoutes: IStaticDashboardRoute[] = RoutingService.createStaticDashboardRoutes(staticDashboards);

export default [...staticRoutes, rootRoute];
