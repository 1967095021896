import BrowserHistoryState from '@/store/BrowserHistoryState';
import URLWrapper from '@/store/URLWrapper';
import URLGridParameterConfig from '@/store/URLGridParameterConfig';
import URLGraphParameterConfig from '@/store/URLGraphParameterConfig';

export default class URLParameterConfig {
    applyGridParameters(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const urlGridParameterConfig = new URLGridParameterConfig();
        urlGridParameterConfig.applyGridParameters(browserHistoryState, urlWrapper);
    }

    applyGraphParameters(browserHistoryState: BrowserHistoryState, urlWrapper: URLWrapper): void {
        const urlGraphParameterConfig = new URLGraphParameterConfig();
        urlGraphParameterConfig.applyGraphParameters(browserHistoryState, urlWrapper);
    }
}
