import Dimension from '@/components/grid/Dimension';
import ChartLine from '@/components/graph/ChartLine';

export class SlicerChartLineExtractor {
    extract(dimension: Dimension): ChartLine {
        if (dimension.id === null && dimension.description === '') {
            return ChartLine.of('(empty value)', '(empty value)');
        }
        if (dimension.id === null) {
            return ChartLine.of(dimension.description, dimension.description);
        }
        if (!dimension.description) {
            return ChartLine.of(dimension.id, `${dimension.id}`);
        }
        return ChartLine.of(dimension.id, `${dimension.id} (${dimension.description})`);
    }
}
