import IowResponseRow from '@/components/graph/api/iow/response/IowResponseRow';
import GraphParameters from '@/components/graph/api/GraphParameters';
import ChartLine from '@/components/graph/ChartLine';
import SingleIowResponse from '@/components/graph/api/iow/response/SingleIowResponse';
import LineSeriesOptionsFactory from '@/components/graph/LineSeriesOptionsFactory';

type PlainPoint = Record<string, string | number>;
export default class MetricPoint {
    private category: string;

    private originCategory: string;

    private metric: string;

    private value: number;

    private gridChartLines: Record<string, number>;

    static of(
        graphParameters: GraphParameters,
        singleIowResponse: SingleIowResponse,
        row: IowResponseRow,
        relatedCategory: string = null,
    ): MetricPoint {
        const metricPoint = new MetricPoint();
        const metric = singleIowResponse.isTimeShift
            ? `${LineSeriesOptionsFactory.TIME_SHIFT_PREFIX}${singleIowResponse.metric}`
            : singleIowResponse.metric;
        metricPoint.metric = metric;
        metricPoint.value = row.total;
        metricPoint.gridChartLines = Object.entries(row)
            .filter(([rowKey]) =>
                graphParameters.chartLines.some((currentChartLine) => currentChartLine.isEqual(ChartLine.of(rowKey))),
            )
            .reduce((accumulator, chartLine) => {
                accumulator[`${metric}|${chartLine[0]}`] = chartLine[1];
                return accumulator;
            }, {});

        metricPoint.category = relatedCategory || row.time;
        metricPoint.originCategory = row.time;

        return metricPoint;
    }

    toPlain(): PlainPoint {
        return {
            ...this.gridChartLines,
            category: this.category,
            originCategory: this.originCategory,
            [this.metric]: this.value,
        };
    }

    toSeparatePlainPoints(): Record<string, PlainPoint[]> {
        const points: Record<string, PlainPoint[]> = {};
        const plainPoint: PlainPoint = {
            category: this.category,
            originCategory: this.originCategory,
            [this.metric]: this.value,
        };
        points[this.metric] = [plainPoint];

        this.populateGridChartLinePoints(points);
        return points;
    }

    private populateGridChartLinePoints(points: {}): void {
        Object.entries(this.gridChartLines).forEach(([metricName, metricValue]): void => {
            const linePlainPoint: PlainPoint = {
                category: this.category,
                originCategory: this.originCategory,
                [metricName]: metricValue,
            };
            points[metricName] = [linePlainPoint];
        });
    }
}

export { PlainPoint };
