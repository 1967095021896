import IowResponseRows from '@/components/grid/api/iow/response/IowResponseRows';
import IowMetricsTransformer from '@/components/grid/api/iow/response/IowMetricsTransformer';
import IowDimensionsTransformer from '@/components/grid/api/iow/response/IowDimensionsTransformer';
import IowRowValidator from '@/components/grid/api/iow/response/IowRowValidator';
import IowResponseTotal from '@/components/grid/api/iow/response/IowResponseTotal';

export default class IowRowsTransformer {
    protected readonly iowRowValidator: IowRowValidator;

    constructor() {
        this.iowRowValidator = new IowRowValidator();
    }

    transform(rows: (IowResponseRows | IowResponseTotal)[], groups: string[] = []) {
        return rows.reduce((accumulator, row: IowResponseRows) => {
            if (this.iowRowValidator.validate(row, groups)) {
                const iowMetricsTransformer = new IowMetricsTransformer();
                const iowDimensionsTransformer = new IowDimensionsTransformer();
                const metrics = iowMetricsTransformer.transform(row.data);
                const dimensions = iowDimensionsTransformer.transform(row.name, row.mapping, groups);
                const gridRow = { ...metrics, ...dimensions };
                accumulator.push(gridRow);
            }
            return accumulator;
        }, []);
    }
}
