import ChartLineTransformer from '@/components/grid/ChartLineTransformer';
import { SlicerChartLineExtractor } from '@/components/grid/SlicerChartLineExtractor';
import IChartLineCollection from '@/components/graph/IChartLineCollection';
import store from '@/store';
import RowStyle from '@/components/grid/RowStyle';

const graphLineColumn = {
    headerName: '-',
    field: 'graph-line',
    colId: 'graph-line',
    pinned: true,
    lockPosition: true,
    lockPinned: true,
    width: 100,
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    maxWidth: 30,
    resizable: false,
    flex: 1,
    sortable: false,
    headerClass: 'grid-lines',
    headerComponent: 'GraphCellRenderer',
    cellClass: 'grid-lines',
    cellStyle: (params) => {
        const chartLineTransformer = new ChartLineTransformer(new SlicerChartLineExtractor());
        const chartLine = chartLineTransformer.transform(params.data);
        const chartLineCollection: IChartLineCollection = store.getters.getGraphReference.getChartLineCollection();
        const currentChartLine = chartLineCollection.get(chartLine);
        if (currentChartLine) {
            return RowStyle.of({ color: currentChartLine.color, visibility: 'visible' }).style;
        }
        return { visibility: 'hidden' };
    },
    cellRenderer: (params) => {
        const chartLineTransformer = new ChartLineTransformer(new SlicerChartLineExtractor());
        const chartLine = chartLineTransformer.transform(params.data);
        const chartLineCollection: IChartLineCollection = store.getters.getGraphReference.getChartLineCollection();
        const chartLineIndex = chartLineCollection.indexOf(chartLine);
        if (chartLineIndex === -1) {
            return `<span />`;
        }
        const selectedLineNumber = chartLineCollection.size() - chartLineIndex;
        return `<span>-</span><span class='row-line-counter'>${selectedLineNumber}</span>`;
    },
};

export default graphLineColumn;
