export default class RowStyle {
    private _style: string;

    static of(style: any): RowStyle {
        const rowStyle = new RowStyle();
        rowStyle._style = style;
        return rowStyle;
    }

    get style() {
        return this._style;
    }
}
