import { ColDef } from 'ag-grid-community';
import Dimension from '@/components/grid/Dimension';

const dimensionsWithoutIdVisibility = ['supply.seller_network'];
const dimensions: ColDef[] = [
    {
        headerName: 'Advertiser',
        showRowGroup: 'demand.advertiser_id',
        hide: false,
        pinned: 'left',
    },
    {
        headerName: 'Campaign',
        showRowGroup: 'demand.campaign_id',
        hide: false,
        pinned: 'left',
    },
    {
        headerName: 'Line Item',
        showRowGroup: 'demand.line_item_id',
    },
    {
        headerName: 'Holding',
        showRowGroup: 'demand.holding_id',
    },
    {
        headerName: 'Page Domain',
        showRowGroup: 'supply.page_domain',
    },
    {
        headerName: 'Country',
        showRowGroup: 'user.geo.country',
    },
    {
        headerName: 'Region',
        showRowGroup: 'user.geo.country_region',
    },
    {
        headerName: 'City',
        showRowGroup: 'user.geo.city',
    },
    {
        headerName: 'Creative',
        showRowGroup: 'demand.creative_id',
    },
    {
        headerName: 'Creative Size',
        showRowGroup: 'demand.creative_size',
    },
    {
        headerName: 'SSP',
        showRowGroup: 'supply.seller_network',
    },
    {
        headerName: 'Browser',
        showRowGroup: 'user.agent.browser.name',
    },
    {
        headerName: 'OS',
        showRowGroup: 'user.agent.os.name',
    },
    {
        headerName: 'Device Type',
        showRowGroup: 'user.agent.device.type',
    },
    {
        headerName: 'App Name',
        showRowGroup: 'supply.app_name',
    },
    {
        headerName: 'App ID',
        showRowGroup: 'supply.app_id',
    },
    {
        headerName: 'Inventory Type',
        showRowGroup: 'inventory_type',
    },
    {
        headerName: 'Alternative Currency',
        showRowGroup: 'demand.alternative_currency',
    },
    {
        headerName: 'Year',
        showRowGroup: 'granularity_year',
    },
    {
        headerName: 'Quarter',
        showRowGroup: 'granularity_quarter',
    },
    {
        headerName: 'Month',
        showRowGroup: 'granularity_month',
    },
    {
        headerName: 'Week',
        showRowGroup: 'granularity_week',
    },
    {
        headerName: 'Day',
        showRowGroup: 'granularity_day',
    },
    {
        headerName: 'Hour',
        showRowGroup: 'granularity_hour',
    },
    {
        headerName: 'Conversion',
        showRowGroup: 'conversion_id',
    },
    {
        headerName: 'Content Type',
        showRowGroup: 'supply.content_type',
    },
    {
        headerName: 'Fingerprint Conversion Flag',
        showRowGroup: 'is_fingerprinted_conv',
    },
    {
        headerName: 'DCO Flag',
        showRowGroup: 'demand.is_dco',
    },
    {
        headerName: 'MMP',
        showRowGroup: 'mmp',
    },
];

let lastActiveDimensionIndex;
dimensions.forEach((dimension, index) => {
    if (dimension.hide === false) {
        lastActiveDimensionIndex = index;
    }
});

function compareDimensions(currentDimension, previousRow, column) {
    if (!previousRow || !previousRow.data || !previousRow.data[column] || !currentDimension) {
        return false;
    }

    return currentDimension.compare(previousRow.data[column]);
}

export default dimensions.reduce((accumulator, dimension) => {
    accumulator.push({
        colId: `${dimension.showRowGroup}`,
        field: `${dimension.showRowGroup}`,
        headerName: dimension.headerName,
        headerClass: 'dimensions',
        filter: 'agMultiColumnFilter',
        filterParams: {
            filters: [
                {
                    filterFramework: 'CustomDimensionFilter',
                },
                {
                    filter: 'agTextColumnFilter',
                    filterParams: {
                        filterOptions: ['contains', 'not contains', 'beginswith', 'endswith'],
                        defaultOption: 'contains',
                        maxNumConditions: 1,
                        buttons: ['reset', 'apply'],
                    },
                },
            ],
        },
        cellClassRules: {
            'cell-duplicate': (params) => {
                const previousRow = params.api.getDisplayedRowAtIndex(params.rowIndex - 1);
                const currentColumn = params.colDef.colId;
                const currentDimension: Dimension = params.value;

                return compareDimensions(currentDimension, previousRow, currentColumn);
            },
        },
        menuTabs: ['generalMenuTab', 'filterMenuTab'],
        hide: dimension.hide !== false,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'DimensionCellRenderer',
    });
    return accumulator;
}, []);

export { dimensions, dimensionsWithoutIdVisibility };
