<template>
    <span v-if="isNull" />
    <span v-else-if="isUnknown" class="dimension-cell">Unknown</span>
    <span v-else-if="!dimension.id" class="dimension-cell">{{ dimension.description }}</span>
    <span v-else class="dimension-cell">{{ dimension.id }} {{ dimension.description }}</span>
</template>

<script>
export default {
    name: 'DimensionCellRenderer',
    data() {
        return {
            dimension: {},
        };
    },
    computed: {
        isNull() {
            return Boolean(!Object.keys(this.dimension).length);
        },
        isUnknown() {
            return !this.dimension.id && !this.dimension.description;
        },
    },
    beforeMount() {
        const dimension = this.params.value;
        const isTotalRow = !dimension;
        if (isTotalRow) {
            return;
        }
        this.dimension = dimension;
    },
};
</script>
