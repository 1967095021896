import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { formatUnknownValues, NumberFormatter } from '@/components/grid/NumberFormatter';

export default class PercentFormatter extends NumberFormatter {
    format(params: ValueFormatterParams): string {
        if (params.value > 1) {
            return 'N/A';
        }
        const formatter = new Intl.NumberFormat(this.locale, this.options);

        return formatUnknownValues(formatter.format(params.value));
    }
}
