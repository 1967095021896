import { Color } from '@amcharts/amcharts4/core';

export default class ChartLine {
    private _value: string;

    private _description: string;

    private _color: Color;

    static of(value: string, description = ''): ChartLine {
        const chartLine = new ChartLine();
        chartLine._value = value;
        chartLine._description = description;

        return chartLine;
    }

    isEqual(chartLine: ChartLine) {
        return this._value === chartLine._value;
    }

    merge(extractedChartLine: ChartLine): ChartLine {
        const value = this._value;
        if (!value) {
            return extractedChartLine;
        }
        return ChartLine.of(
            `${this._value}\u0002${extractedChartLine._value}`,
            `${this._description} + ${extractedChartLine._description}`,
        );
    }

    get value() {
        return this._value;
    }

    get description() {
        return this._description;
    }

    set color(color) {
        this._color = color;
    }

    get color() {
        return this._color;
    }
}
