import IApiRequest from '@/components/grid/api/IApiRequest';
import GraphComponentFactory from '@/components/graph/GraphComponentFactory';
import IowRequest from '@/components/graph/api/iow/request/IowRequest';
import IRequestParameterTransformer from '@/components/graph/api/IRequestParameterTransformer';
import IowRequestsParameterTransformer from '@/components/graph/api/iow/request/IowRequestsParameterTransformer';
import IResponseTransformer from '@/components/graph/api/IResponseTransformer';
import IowResponseTransformer from '@/components/graph/api/iow/response/IowResponseTransformer';
import IMultiResponseTransformer from '@/components/graph/api/IMultiResponseTransformer';
import IowFilterGenerator from '@/components/grid/api/iow/request/IowFilterGenerator';
import IowConditionsFilterTransformer from '@/components/grid/api/iow/request/IowConditionsFilterTransformer';
import IowFilterModelTransformer from '@/components/grid/api/iow/request/IowFilterModelTransformer';
import IowMultiFilterModelTransformer from '@/components/grid/api/iow/request/IowMultiFilterModelTransformer';
import IowFiltersTransformer from '@/components/grid/api/iow/request/IowFiltersTransformer';
import DimensionFilter from '@/components/grid/api/DimensionFilter';
import MetricFilter from '@/components/grid/api/MetricFilter';
import IowMetricFilterTemplateGenerator from '@/components/grid/api/iow/request/IowMetricFilterTemplateGenerator';
import IowResponseCollection from '@/components/graph/api/iow/response/IowResponseCollection';
import IowMultiResponseSplitTransformer from '@/components/graph/api/iow/response/IowMultiResponseSplitTransformer';

export default class IowComponentFactory extends GraphComponentFactory {
    createApiRequest(): IApiRequest {
        return new IowRequest();
    }

    createRequestParameterTransformer(): IRequestParameterTransformer {
        const iowFilterGenerator = new IowFilterGenerator();
        const iowConditionsFilterTransformer = new IowConditionsFilterTransformer(iowFilterGenerator);
        const iowFilterModelTransformer = new IowFilterModelTransformer(iowConditionsFilterTransformer, iowFilterGenerator);
        const iowMultiFilterTransformer = new IowMultiFilterModelTransformer(iowFilterModelTransformer);
        const iowFiltersTransformer = new IowFiltersTransformer(iowFilterModelTransformer, iowMultiFilterTransformer);
        return new IowRequestsParameterTransformer(
            iowFiltersTransformer,
            new DimensionFilter(),
            new MetricFilter(),
            new IowMetricFilterTemplateGenerator(),
        );
    }

    createResponseTransformer(): IResponseTransformer {
        return new IowResponseTransformer();
    }

    createMultiResponseTransformer(): IMultiResponseTransformer {
        return new IowMultiResponseSplitTransformer();
    }

    createResponseCollection(): IowResponseCollection {
        return new IowResponseCollection();
    }
}
