import IowResponseTotal from '@/components/grid/api/iow/response/IowResponseTotal';
import IowRowsTransformer from '@/components/grid/api/iow/response/IowRowsTransformer';
import IowRequestParameters from '@/components/grid/api/iow/request/IowRequestParameters';

export default class IowTotalRowsTransformer {
    private readonly request: IowRequestParameters;

    private readonly iowResponseTransformer: IowRowsTransformer;

    constructor(iowResponseTransformer: IowRowsTransformer, request: IowRequestParameters) {
        this.iowResponseTransformer = iowResponseTransformer;
        this.request = request;
    }

    transform(totalResponse: IowResponseTotal) {
        const totalRows = this.iowResponseTransformer.transform([totalResponse]);
        if (totalRows.length && this.request.splitBy.length) {
            this.request.splitBy.forEach((groupKey) => {
                totalRows[0][groupKey] = '';
            });
            const totalRow = {
                fullWidth: true,
                totalRows: totalResponse.recordsFound,
            };
            totalRows.unshift(totalRow);
        }
        return totalRows;
    }
}
