import { Expose, Type } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';
import IowResponseRow from '@/components/grid/api/iow/response/IowResponseRow';

export default class IowResponseRows {
    @Expose()
    @Type(() => IowResponseRow)
    data: IowResponseRow[];

    @Expose({ name: 'confidence_range' })
    confidenceRange: number;

    @Expose()
    mapping: string[] | string;

    @Expose()
    name: string[] | string;
}
