import { Expose } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';

export default class IowMetricFilter {
    @Expose()
    match: string;

    @Expose()
    value: string[];

    @Expose()
    name: string;
}
