import { classToPlain, plainToClass } from 'class-transformer';
import { format, subDays } from 'date-fns';
import IGlobalFilter from '@/components/grid/IGlobalFilter';
import GraphParameters from '@/components/graph/api/GraphParameters';
import IRequestParameterTransformer from '@/components/graph/api/IRequestParameterTransformer';
import IowRequestParameters from '@/components/graph/api/iow/request/IowRequestParameters';
import IowFiltersTransformer from '@/components/grid/api/iow/request/IowFiltersTransformer';
import DimensionFilter from '@/components/grid/api/DimensionFilter';
import MetricFilter from '@/components/grid/api/MetricFilter';
import IowMetricFilterTemplateGenerator from '@/components/grid/api/iow/request/IowMetricFilterTemplateGenerator';

export default class IowRequestsParameterTransformer implements IRequestParameterTransformer {
    private readonly iowFiltersTransformer: IowFiltersTransformer;

    private readonly dimensionFilter: DimensionFilter;

    private readonly metricFilter: MetricFilter;

    private readonly iowMetricFilterTemplateGenerator: IowMetricFilterTemplateGenerator;

    constructor(
        iowFiltersTransformer: IowFiltersTransformer,
        dimensionFilter: DimensionFilter,
        metricFilter: MetricFilter,
        iowMetricFilterTemplateGenerator: IowMetricFilterTemplateGenerator,
    ) {
        this.iowFiltersTransformer = iowFiltersTransformer;
        this.dimensionFilter = dimensionFilter;
        this.metricFilter = metricFilter;
        this.iowMetricFilterTemplateGenerator = iowMetricFilterTemplateGenerator;
    }

    transform(graphParameters: GraphParameters, globalFilter: IGlobalFilter): IowRequestParameters[] {
        const timeShiftRequests = [];
        const requests = graphParameters.metrics.map((metric) => {
            const iowRequestParameters = this.transformsMetricToRequestParameters(globalFilter, graphParameters, metric);
            if (graphParameters.timeShift) {
                const timeShiftRequestParameters = this.createTimeShiftRequestParameters(graphParameters, iowRequestParameters);
                timeShiftRequests.push(timeShiftRequestParameters);
            }
            return iowRequestParameters;
        });

        return requests.concat(timeShiftRequests);
    }

    private transformsMetricToRequestParameters(
        globalFilter: IGlobalFilter,
        graphParameters: GraphParameters,
        metric: string,
    ): IowRequestParameters {
        const iowRequestParameters = new IowRequestParameters();
        iowRequestParameters.startDate = globalFilter.getStartDate();
        iowRequestParameters.endDate = globalFilter.getEndDate();
        iowRequestParameters.timezone = globalFilter.getTimezone();
        iowRequestParameters.filters = this.iowFiltersTransformer.transform(graphParameters.filterModel, this.dimensionFilter);
        iowRequestParameters.dataFilters = this.iowFiltersTransformer.transform(graphParameters.filterModel, this.metricFilter);
        iowRequestParameters.dataFilterTemplate = this.iowMetricFilterTemplateGenerator.generate(
            iowRequestParameters.dataFilters,
        );
        iowRequestParameters.dataField = metric;
        iowRequestParameters.splitBy = graphParameters.activeDimensions;
        iowRequestParameters.granularity = graphParameters.granularity;
        iowRequestParameters.chartLines = iowRequestParameters.chartLines.concat(
            graphParameters.chartLines.map((chartLine) => chartLine.value),
        );
        return iowRequestParameters;
    }

    private createTimeShiftRequestParameters(
        graphParameters: GraphParameters,
        iowRequestParameters: IowRequestParameters,
    ): IowRequestParameters {
        const timeShiftRequestParameters: IowRequestParameters = plainToClass(
            IowRequestParameters,
            classToPlain(iowRequestParameters),
        );
        const startDate = new Date(timeShiftRequestParameters.startDate);
        const endDate = new Date(timeShiftRequestParameters.endDate);
        const subStartDate = subDays(startDate, graphParameters.timeShift);
        const subEndDate = subDays(endDate, graphParameters.timeShift);
        timeShiftRequestParameters.startDate = format(subStartDate, 'yyyy-MM-dd');
        timeShiftRequestParameters.endDate = format(subEndDate, 'yyyy-MM-dd');
        timeShiftRequestParameters.timeShift = true;

        return timeShiftRequestParameters;
    }
}
