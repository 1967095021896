import SingleIowResponse from '@/components/graph/api/iow/response/SingleIowResponse';
import ResponseCollection from '@/components/graph/api/ResponseCollection';

export default class IowResponseCollection extends ResponseCollection {
    push(response: SingleIowResponse): void {
        this.responses.push(response);
    }

    getAll(): SingleIowResponse[] {
        return this.responses;
    }
}
