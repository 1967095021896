import { IServerSideGetRowsParams } from 'ag-grid-community';
import IApi from '@/components/grid/api/IApi';
import IRequestParameterTransformer from '@/components/grid/api/IRequestParameterTransformer';
import IApiRequest from '@/components/grid/api/IApiRequest';
import IGlobalFilter from '@/components/grid/IGlobalFilter';
import IResponseTransformer from '@/components/grid/api/IResponseTransformer';
import IApiResult from '@/components/grid/api/IApiResult';

export default class SyncApi implements IApi {
    private readonly apiRequest: IApiRequest;

    private readonly requestTransformer: IRequestParameterTransformer;

    private readonly responseTransformer: IResponseTransformer;

    private readonly globalFilter: IGlobalFilter;

    constructor(
        apiRequest: IApiRequest,
        requestTransformer: IRequestParameterTransformer,
        responseTransformer: IResponseTransformer,
        globalFilter: IGlobalFilter,
    ) {
        this.apiRequest = apiRequest;
        this.requestTransformer = requestTransformer;
        this.responseTransformer = responseTransformer;
        this.globalFilter = globalFilter;
    }

    getData(gridParams: IServerSideGetRowsParams): Promise<IApiResult> {
        const mappedRequest = this.requestTransformer.transform(gridParams, this.globalFilter);
        return new Promise((resolve, reject) => {
            this.apiRequest
                .fetch(mappedRequest)
                .then((response) => {
                    resolve(this.responseTransformer.transform(response, mappedRequest));
                })
                .catch(() => {
                    reject();
                });
        });
    }
}
