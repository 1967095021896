import { Expose } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';

export default class IowResponseTotalRow {
    @Expose()
    name: string;

    @Expose()
    value: any;
}
