import { IDashboard } from '@/dashboards/IDashboard';

export default class StaticDashboardService {
    private readonly staticDashboards: IDashboard[] = [
        {
            title: 'Reporting Studio',
            icon: 'reporting-studio',
            domainName: 'reporting-studio',
            resourceType: 'reporting-studio',
            routeName: 'reporting-studio',
        },
    ];

    get(): IDashboard[] {
        return this.staticDashboards;
    }
}
