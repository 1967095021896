import {
    Column,
    ColumnEvent,
    ColumnMovedEvent,
    ColumnPinnedEvent,
    FilterChangedEvent,
    FilterModifiedEvent,
    GridOptions,
    IsFullWidthRowParams,
    RowClickedEvent,
    SortChangedEvent,
    ToolPanelVisibleChangedEvent,
} from 'ag-grid-community';
import { IFilterComp } from 'ag-grid-community/dist/lib/interfaces/iFilter';
import Vue from 'vue';
import ColumnDefinitionService from '@/components/grid/ColumnDefinitionService';
import store from '@/store';
import FilterToolBar from '@/components/grid/FilterToolBar.vue';
import { SlicerChartLineExtractor } from '@/components/grid/SlicerChartLineExtractor';
import DelayedGroupTrigger from '@/components/DelayedGroupTrigger';
import ChartLineTransformer from '@/components/grid/ChartLineTransformer';
import BrowserHistoryState from '@/store/BrowserHistoryState';
import IChartLineCollection from '@/components/graph/IChartLineCollection';
import ColumnWrapper from '@/components/grid/ColumnWrapper';

const columnDefinitionService = new ColumnDefinitionService();
let filterToolBarInstance;
const delayedGraphUpdateTrigger = new DelayedGroupTrigger();
const delayedGridUpdateTrigger = new DelayedGroupTrigger();
const chartLineTransformer = new ChartLineTransformer(new SlicerChartLineExtractor());
const browserHistory = store.getters['historyStore/browserHistory'];
const updateGridDelay = 1500;
const updateGraphDelay = updateGridDelay;

const gridEvents: GridOptions = {
    onDragStopped(event) {
        const changedColumn: Column = store.getters.getMovedColumn;
        if (changedColumn) {
            const columnEvent: ColumnEvent = { ...event } as any;
            columnEvent.columns = [changedColumn];
            this.onColumnVisible(columnEvent);
        }
    },
    onColumnVisible: (columnEvent: ColumnEvent) => {
        if (!columnEvent.columns.length) {
            return;
        }
        const api = columnEvent.api;
        const columnApi = columnEvent.columnApi;
        const changedColumn = new ColumnWrapper(columnEvent.columns[0], columnDefinitionService, api, columnApi);
        if (changedColumn.isMoving()) {
            store.commit('setMovedColumn', columnEvent.columns[0]);
            return;
        }
        if (changedColumn.isLastRemainingDimension()) {
            changedColumn.changeVisibility(true);
            return;
        }
        if (!changedColumn.wasVisibilityChanged()) {
            return;
        }
        store.commit('historyStore/updateGridParameters', columnApi);
        if (changedColumn.isMetric()) {
            columnEvent.columnApi.moveColumn(columnEvent.columns[0], 25);
        }
        changedColumn.changeVisibility(changedColumn.isVisible());
        if (!changedColumn.isStale()) {
            return;
        }
        delayedGridUpdateTrigger.addEvent(updateGridDelay, () => {
            api.refreshServerSide({ purge: true });
        });
        if (changedColumn.isDimension()) {
            columnEvent.columnApi.moveColumn(columnEvent.columns[0], 1);
            delayedGraphUpdateTrigger.addEvent(updateGraphDelay, () => {
                store.getters.getGraphReference.resetLineSelection();
                store.commit('updateGraph', { api, columnApi });
            });
        }
    },
    onColumnMoved(event: ColumnMovedEvent) {
        if (!event.column) {
            return;
        }

        const didMetricMoveBeforeDimensions = event.toIndex <= 24 && event.column.getColDef().colId === undefined;
        if (didMetricMoveBeforeDimensions) {
            event.columnApi.moveColumn(event.column, 25);
        }
        const didDimensionMoveAfterMetric = event.toIndex > 24 && event.column.getColDef().colId !== undefined;
        if (didDimensionMoveAfterMetric) {
            event.columnApi.moveColumn(event.column, 24);
        }
        store.commit('setMovedColumn', event.column);
        columnDefinitionService.syncToolBar(event, store.getters.getDefaultDimensions, store.getters.getDefaultMetrics);
        store.commit('historyStore/updateGridParameters', event.columnApi);
    },
    onGridReady: (columnEvent: ColumnEvent) => {
        columnDefinitionService.syncToolBar(columnEvent, store.getters.getDefaultDimensions, store.getters.getDefaultMetrics);
        const browserState: BrowserHistoryState = store.getters['historyStore/historyState'];
        columnEvent.api.setFilterModel(browserState.filters);
        browserState.dimensions = columnDefinitionService.getActiveDimensions(columnEvent.columnApi);
        browserState.metrics = columnDefinitionService.getActiveMetrics(columnEvent.columnApi);
        browserState.pinning = columnDefinitionService.getPinnedColumns(columnEvent.columnApi);
        const graphReference = store.getters.getGraphReference;
        if (browserState.chartLines !== undefined) {
            browserState.chartLines.forEach((chartLine) => graphReference.addChartLine(chartLine));
        }
        const filterModel = columnEvent.api.getFilterModel();
        graphReference.updateGraph(filterModel);
        browserHistory.replace(browserState);
    },
    isFullWidthRow(params: IsFullWidthRowParams) {
        if (!params.rowNode.data) {
            return false;
        }
        return params.rowNode.data.fullWidth;
    },
    onFilterChanged(event: FilterChangedEvent) {
        const filterModel = event.api.getFilterModel();
        store.getters.getGraphReference.updateGraph(filterModel);
        const browserState: BrowserHistoryState = store.getters['historyStore/historyState'];
        browserState.filters = filterModel;
        browserHistory.updateGridParameters(browserState);
    },
    onFilterModified(event: FilterModifiedEvent) {
        const columnKey = event.column.getColId();
        // eslint-disable-next-line no-prototype-builtins
        if (event.filterInstance.hasOwnProperty('filters')) {
            // @ts-ignore
            const filters: IFilterComp[] = event.filterInstance.filters;
            filters.forEach((filter, index) => {
                store.commit('addModifiedFilterInstance', {
                    columnKey,
                    index,
                    filterInstance: filter,
                });
            });
        }
    },
    onToolPanelVisibleChanged(event: ToolPanelVisibleChangedEvent) {
        const filterToolPanel = document.querySelector('.ag-tool-panel-wrapper:not(.ag-hidden)');
        if (event.source === 'sideBarButtonClicked') {
            const sideBar = document.querySelector('.ag-side-bar');
            filterToolPanel.classList.add('grid-filters');
            if (!filterToolBarInstance) {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const ComponentClass = Vue.extend(FilterToolBar);
                filterToolBarInstance = new ComponentClass({
                    propsData: { customGridOptions: event },
                    store,
                });
                filterToolBarInstance.$mount();
                sideBar.prepend(filterToolBarInstance.$el);
            }
        } else {
            filterToolBarInstance = null;
            const filterButtons = document.querySelector('.grid-filter-buttons');
            if (filterButtons) {
                filterButtons.remove();
            }
        }
    },
    onRowClicked(event: RowClickedEvent): void {
        if (!event.data || event.node.id === 't-1') {
            return;
        }
        const chartLine = chartLineTransformer.transform(event.data);
        const chartLineCollection: IChartLineCollection = store.getters.getGraphReference.getChartLineCollection();
        chartLineCollection.watchAdd(() => {
            delayedGraphUpdateTrigger.addEvent(updateGraphDelay, () => {
                store.commit('historyStore/updateGridParameters', event.columnApi);
                store.commit('updateGraph', { api: event.api, columnApi: event.columnApi });
            });
        });
        chartLineCollection.watchRemove(() => {
            store.getters.getGraphReference.removeLineSeries(chartLine);
            store.commit('historyStore/updateGridParameters', event.columnApi);
        });
        store.getters.getGraphReference.addChartLine(chartLine);
        event.api.redrawRows();
    },
    onSortChanged(event: SortChangedEvent): void {
        const browserState: BrowserHistoryState = store.getters['historyStore/historyState'];
        browserState.sorting = columnDefinitionService.getColumnsSorting(event.columnApi);
        browserHistory.updateGridParameters(browserState);
    },
    onColumnPinned(event: ColumnPinnedEvent): void {
        const browserState: BrowserHistoryState = store.getters['historyStore/historyState'];
        browserState.pinning = columnDefinitionService.getPinnedColumns(event.columnApi);
        browserHistory.updateGridParameters(browserState);
    },
};

export default gridEvents;
