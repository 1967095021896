import { ColDef } from 'ag-grid-community';
import IRequestParameterTransformer from '@/components/grid/api/IRequestParameterTransformer';
import IApiRequest from '@/components/grid/api/IApiRequest';
import IResponseTransformer from '@/components/grid/api/IResponseTransformer';
import ComponentFactory from '@/components/grid/ComponentFactory';
import IowRequest from '@/components/grid/api/iow/request/IowRequest';
import IowRequestParameterTransformer from '@/components/grid/api/iow/request/IowRequestParameterTransformer';
import IowResponseTransformer from '@/components/grid/api/iow/response/IowResponseTransformer';
import defaultDimensions from '@/components/grid/api/iow/defaultDimensions';
import defaultMetrics from '@/components/grid/api/iow/defaultMetrics';
import IowFiltersTransformer from '@/components/grid/api/iow/request/IowFiltersTransformer';
import IowFilterGenerator from '@/components/grid/api/iow/request/IowFilterGenerator';
import IowConditionsFilterTransformer from '@/components/grid/api/iow/request/IowConditionsFilterTransformer';
import IowMultiFilterModelTransformer from '@/components/grid/api/iow/request/IowMultiFilterModelTransformer';
import IowFilterModelTransformer from '@/components/grid/api/iow/request/IowFilterModelTransformer';
import DimensionFilter from '@/components/grid/api/DimensionFilter';
import MetricFilter from '@/components/grid/api/MetricFilter';
import IowMetricFilterTemplateGenerator from '@/components/grid/api/iow/request/IowMetricFilterTemplateGenerator';
import ColumnDefinitionService from '@/components/grid/ColumnDefinitionService';

export default class IowComponentFactory extends ComponentFactory {
    createApiRequest(): IApiRequest {
        return new IowRequest();
    }

    createRequestParameterTransformer(): IRequestParameterTransformer {
        const iowFilterGenerator = new IowFilterGenerator();
        const iowConditionsFilterTransformer = new IowConditionsFilterTransformer(iowFilterGenerator);
        const iowFilterModelTransformer = new IowFilterModelTransformer(iowConditionsFilterTransformer, iowFilterGenerator);
        const iowMultiFilterTransformer = new IowMultiFilterModelTransformer(iowFilterModelTransformer);
        const iowFiltersTransformer = new IowFiltersTransformer(iowFilterModelTransformer, iowMultiFilterTransformer);
        return new IowRequestParameterTransformer(
            iowFiltersTransformer,
            new DimensionFilter(),
            new MetricFilter(),
            new IowMetricFilterTemplateGenerator(),
            new ColumnDefinitionService(),
        );
    }

    createResponseTransformer(): IResponseTransformer {
        return new IowResponseTransformer();
    }

    createDimensions(): ColDef[] {
        return defaultDimensions;
    }

    createMetrics(): ColDef[] {
        return defaultMetrics;
    }
}
