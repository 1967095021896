import { Expose } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';

export default class IowResponseRow {
    @Expose()
    name: string;

    @Expose()
    value: any;

    @Expose()
    percent: string;
}
