(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("@ads/design-system"), require("vue-router"));
	else if(typeof define === 'function' && define.amd)
		define(["vue", "@ads/design-system", "vue-router"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("vue"), require("@ads/design-system"), require("vue-router")) : factory(root["vue"], root["@ads/design-system"], root["vue-router"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__2380__, __WEBPACK_EXTERNAL_MODULE__91753__, __WEBPACK_EXTERNAL_MODULE__16154__) {
return 