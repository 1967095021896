export default class DimensionDescriptionTransformer {
    transform(mapping: string | string[], index: number = 0): string {
        if (mapping instanceof Array) {
            mapping = this.getMappingString(mapping, index);
        }
        return mapping || '';
    }

    private getMappingString(mapping: string[], index: number) {
        return mapping[index];
    }
}
