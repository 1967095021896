import { Axis } from '@amcharts/amcharts4/charts';
import GraphParameters from '@/components/graph/api/GraphParameters';
import Chart from '@/components/graph/Chart';
import ChartLine from '@/components/graph/ChartLine';
import LineSeriesOptions from '@/components/graph/LineSeriesOptions';

export default class LineSeriesOptionsFactory {
    private readonly graphParameters: GraphParameters;

    private readonly response: any;

    private readonly metricLabels: string[];

    static TIME_SHIFT_PREFIX = 'timeshift_';

    constructor(graphParameters: GraphParameters, response: any, metricLabels: string[]) {
        this.graphParameters = graphParameters;
        this.response = response;
        this.metricLabels = metricLabels;
    }

    create(identifier: string, metric: string, metricIndex: number, yAxis: Axis) {
        const isTimeShift = this.isTimeShift(identifier);
        const opacity = isTimeShift ? 0.3 : 1;
        const chartLine = this.getChartLine(identifier);
        if (chartLine) {
            return new LineSeriesOptions({
                data: this.response[identifier],
                groupName: chartLine.description,
                identifier,
                metric,
                metricIndex,
                metricLabel: this.metricLabels[metric],
                granularity: this.graphParameters.granularity,
                yAxis,
                color: chartLine.color,
                opacity,
            });
        }

        return new LineSeriesOptions({
            data: this.response[identifier],
            groupName: 'Total',
            identifier,
            metric,
            metricIndex,
            metricLabel: this.metricLabels[metric],
            granularity: this.graphParameters.granularity,
            yAxis,
            color: Chart.TOTAL_COLOR,
            opacity,
        });
    }

    private isTimeShift(metric: string) {
        return metric.includes(LineSeriesOptionsFactory.TIME_SHIFT_PREFIX);
    }

    private getChartLine(metric: string): ChartLine {
        return this.graphParameters.chartLines.find((chartLine) => metric.includes(chartLine.value));
    }
}
