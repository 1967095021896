import IResponseTransformer from '@/components/graph/api/IResponseTransformer';
import IowResponse from '@/components/graph/api/iow/response/IowResponse';
import SingleIowResponse from '@/components/graph/api/iow/response/SingleIowResponse';
import IowRequestParameters from '@/components/graph/api/iow/request/IowRequestParameters';

export default class IowResponseTransformer implements IResponseTransformer {
    transform(iowResponse: IowResponse, iowRequestParameters: IowRequestParameters): SingleIowResponse {
        const singleIowResponse = new SingleIowResponse();
        singleIowResponse.response = iowResponse;
        singleIowResponse.metric = iowRequestParameters.dataField;
        singleIowResponse.isTimeShift = iowRequestParameters.timeShift;
        return singleIowResponse;
    }
}
