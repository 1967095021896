<template>
    <div class="grid-filter-buttons">
        <SrButton color="primary" text @click="resetFilters"> Reset Filters </SrButton>
        <SrButton color="primary" text @click="apply"> Apply Filters </SrButton>
    </div>
</template>

<script>
import { SrButton } from '@ads/design-system';
import GridFilterService from '@/components/grid/GridFilterService';

export default {
    name: 'FilterToolBar',
    components: {
        SrButton,
    },
    props: {
        customGridOptions: {
            type: Object,
            required: true,
        },
    },
    data() {
        const gridFilterService = new GridFilterService();
        return {
            gridFilterService,
        };
    },
    methods: {
        apply() {
            this.gridFilterService.applyAllFilters(this.customGridOptions.api);
        },
        resetFilters() {
            this.gridFilterService.resetAllFilters(this.customGridOptions.api);
        },
    },
};
</script>
