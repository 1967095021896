import 'es6-shim';
import 'reflect-metadata';
import { Exclude, Expose } from 'class-transformer';
import SortColumn from '@/store/SortColumn';
import PinnedColumn from '@/store/PinnedColumn';
import ChartLine from '@/components/graph/ChartLine';

@Exclude()
export default class BrowserHistoryState {
    @Expose()
    dimensions: string[] = [];

    @Expose()
    metrics: string[] = [];

    @Expose()
    filters: { [p: string]: any };

    @Expose()
    sorting: SortColumn[] = [];

    @Expose()
    pinning: PinnedColumn[] = [];

    @Expose()
    firstMetric: string;

    @Expose()
    secondMetric: string;

    @Expose()
    granularity: string;

    @Expose()
    timeShift: string;

    @Expose()
    chartLines: ChartLine[];

    @Expose()
    dateFrom: string;

    @Expose()
    dateTo: string;

    @Expose()
    timezone: string;

    @Expose()
    dateRangeSelection: string;
}
