import IColumnFilter from '@/components/grid/api/IColumnFilter';
import { dimensions } from '@/components/grid/api/iow/defaultDimensions';

export default class DimensionFilter implements IColumnFilter {
    filter(filterModel: any): any {
        return Object.keys(filterModel)
            .filter((columnKey) => Boolean(dimensions.find((dimension) => dimension.showRowGroup === columnKey)))
            .reduce((accumulator, key) => {
                accumulator[key] = filterModel[key];
                return accumulator;
            }, {});
    }
}
