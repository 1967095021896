import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';

export abstract class NumberFormatter {
    readonly locale: string;

    readonly options: Intl.NumberFormatOptions;

    public abstract format(params: ValueFormatterParams): string;
}

function formatUnknownValues(formattedValue: string): string {
    if (formattedValue.includes('NaN')) {
        return '';
    }
    return formattedValue;
}

export { formatUnknownValues };
