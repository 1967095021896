import * as am4core from '@amcharts/amcharts4/core';
import { XYChart } from '@amcharts/amcharts4/charts';

export default class ChartOverlay {
    private indicator: am4core.Container;

    constructor(chart: XYChart) {
        this.init(chart);
    }

    get _indicator() {
        return this.indicator;
    }

    init(chart: XYChart) {
        const indicator = chart.tooltipContainer.createChild(am4core.Container);
        indicator.background.fill = am4core.color('#fff');
        indicator.background.fillOpacity = 0.8;
        indicator.width = am4core.percent(100);
        indicator.height = am4core.percent(100);
        indicator.hide();
        const indicatorLabel = indicator.createChild(am4core.Label);
        indicatorLabel.text = 'Loading...';
        indicatorLabel.align = 'center';
        indicatorLabel.valign = 'middle';
        indicatorLabel.fontSize = 20;
        this.indicator = indicator;
    }

    show() {
        this.indicator.show();
    }

    hide() {
        this.indicator.hide();
    }
}
