import BrowserHistoryState from '@/store/BrowserHistoryState';
import URLWrapper from '@/store/URLWrapper';
import BrowserHistoryStateConfig from '@/store/BrowserHistoryStateConfig';

export default class BrowserHistory {
    private readonly urlWrapper: URLWrapper;

    private readonly browserHistoryStateConfig: BrowserHistoryStateConfig;

    constructor(urlWrapper: URLWrapper, browserHistoryStateConfig: BrowserHistoryStateConfig) {
        this.urlWrapper = urlWrapper;
        this.browserHistoryStateConfig = browserHistoryStateConfig;
    }

    updateGridParameters(browserHistoryState: BrowserHistoryState): void {
        this.urlWrapper.updateGridSearchParameters(browserHistoryState);
        window.history.pushState(browserHistoryState, 'Reporting-Studio', this.urlWrapper.getURLString());
    }

    updateGraphParameters(browserHistoryState: BrowserHistoryState): void {
        this.urlWrapper.updateGraphSearchParameters(browserHistoryState);
        window.history.pushState(browserHistoryState, 'Reporting-Studio', this.urlWrapper.getURLString());
    }

    replace(browserHistoryState: BrowserHistoryState): void {
        this.urlWrapper.updateGridSearchParameters(browserHistoryState);
        window.history.replaceState(browserHistoryState, 'Reporting-Studio', this.urlWrapper.getURLString());
    }

    getCurrentState(): any {
        return window.history.state;
    }

    getStateFromUrl(): BrowserHistoryState {
        return this.browserHistoryStateConfig.applyURLState();
    }
}
