import { ColorSet } from '@amcharts/amcharts4/core';
import { Color } from '@amcharts/amcharts4/.internal/core/utils/Color';
import ChartLine from '@/components/graph/ChartLine';
import IChartLineCollection from '@/components/graph/IChartLineCollection';
import CustomChartLineCollection from '@/components/graph/CustomChartLineCollection';

export default class ColoredChartLineCollection extends CustomChartLineCollection {
    private readonly colorSet: ColorSet;

    constructor(chartLineCollection: IChartLineCollection, colorSet: ColorSet) {
        super(chartLineCollection);
        this.colorSet = colorSet;
    }

    add(chartLine: ChartLine): void {
        if (!chartLine) {
            return;
        }
        this.chartLineCollection.add(chartLine);
        if (!chartLine.color) {
            chartLine.color = this.findNextColor();
        }
    }

    private findNextColor(): Color {
        const allChartLines = this.chartLineCollection.getAll();
        let retries = 0;
        let nextColor = this.colorSet.next();
        while (this.isColorEqual(allChartLines, nextColor)) {
            if (retries >= this.colorSet.list.length) {
                break;
            }
            nextColor = this.colorSet.next();
            retries++;
        }

        return nextColor;
    }

    private isColorEqual(allChartLines: ChartLine[], nextColor: Color) {
        return allChartLines.some((chartLine) => chartLine.color && chartLine.color.toString() === nextColor.toString());
    }
}
