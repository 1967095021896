import { ColDef, ColumnApi, ColumnEvent, IFiltersToolPanel, IPrimaryColsPanel } from 'ag-grid-community';
import defaultDimensions from '@/components/grid/api/iow/defaultDimensions';
import SortColumn, { DIRECTION } from '@/store/SortColumn';
import PinnedColumn, { ORIENTATION } from '@/store/PinnedColumn';
import defaultMetrics from './api/iow/defaultMetrics';

export default class ColumnDefinitionService {
    getActiveDimensions(columnApi: ColumnApi): string[] {
        return this.getColumnDefinitions(columnApi).reduce((accumulator, column: ColDef) => {
            const isGroupColumn = defaultDimensions.some((dimension) => dimension.field === column.colId);
            if (!column.hide && column.colId && isGroupColumn) {
                accumulator.push(column.colId);
            }
            return accumulator;
        }, []);
    }

    getColumnDefinitions(columnApi: ColumnApi) {
        const columns = columnApi.getAllDisplayedColumns();
        return columns.map((column) => column.getColDef());
    }

    getActiveMetrics(columnApi: ColumnApi) {
        return this.getColumnDefinitions(columnApi).reduce((accumulator, column: ColDef) => {
            const isMetricColumn = defaultMetrics.some((metric) => metric.field === column.field);
            if (!column.hide && column.field && isMetricColumn) {
                accumulator.push(column.field);
            }
            return accumulator;
        }, []);
    }

    getColumnsSorting(columnApi: ColumnApi): SortColumn[] {
        const columns = columnApi.getAllDisplayedColumns();
        return columns
            .filter((column) => column.getSortIndex() !== undefined && column.getSortIndex() !== null)
            .sort((columnA, columnB) => columnA.getSortIndex() - columnB.getSortIndex())
            .map((column) => ({
                name: column.getColId(),
                direction: DIRECTION[column.getSort()],
            }));
    }

    getPinnedColumns(columnApi: ColumnApi): PinnedColumn[] {
        const columns = columnApi.getAllDisplayedColumns();
        return columns
            .filter(
                (column) =>
                    column.getPinned() !== undefined && column.getPinned() !== null && typeof column.getPinned() !== 'boolean',
            )
            .map((column) => ({
                name: column.getColId(),
                orientation: ORIENTATION[column.getPinned() as string],
            }));
    }

    syncToolBar(columnEvent: ColumnEvent, dimensions: ColDef[], metrics: ColDef[]) {
        const dimensionsToolPanel: IPrimaryColsPanel = columnEvent.api.getToolPanelInstance('dimensions');
        dimensionsToolPanel.setColumnLayout([...dimensions]);
        const metricsToolPanel: IPrimaryColsPanel = columnEvent.api.getToolPanelInstance('metrics');
        metricsToolPanel.setColumnLayout([...metrics]);
        const filtersToolPanel: IFiltersToolPanel = columnEvent.api.getToolPanelInstance('filters') as any;
        filtersToolPanel.setFilterLayout([...dimensions, ...metrics]);
    }
}
