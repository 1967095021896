import { IRowModel } from 'ag-grid-community/dist/lib/interfaces/iRowModel';
import IApiRequest from '@/components/grid/api/IApiRequest';
import IExportTransformer from '@/components/grid/api/IExportTransformer';
import FileApi from '@/components/grid/api/FileApi';
import BlobTransformer from '@/components/grid/BlobTransformer';

export default abstract class ExportComponentFactory {
    abstract createApiRequest(): IApiRequest;

    abstract createExportRequestTransformer(): IExportTransformer;

    createBlobTransformer(): BlobTransformer {
        return new BlobTransformer();
    }

    createApi(model: IRowModel): FileApi {
        const apiRequest = this.createApiRequest();
        const requestTransformer = this.createExportRequestTransformer();
        const blobTransformer = this.createBlobTransformer();

        return new FileApi(model, apiRequest, requestTransformer, blobTransformer);
    }
}
