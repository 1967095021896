import { LineSeries } from '@amcharts/amcharts4/charts';
import { Color } from '@amcharts/amcharts4/.internal/core/utils/Color';
import LineSeriesService from '@/components/graph/LineSeriesService';
import ChartLine from '@/components/graph/ChartLine';

export default class LineSeriesGroups {
    /**
     * Holds the LineSeries (lines) for each group (Total, Fun88 advertiser,...).
     * @private
     */
    private groups: any = {};

    private groupColors: any = {};

    private readonly lineSeriesService: LineSeriesService;

    constructor(lineSeriesService: LineSeriesService) {
        this.lineSeriesService = lineSeriesService;
    }

    add(groupName: string, lineSeries: LineSeries, color: Color): void {
        if (!this.groups[groupName]) {
            this.groups[groupName] = [];
            const legend = this.lineSeriesService.createLegend(groupName, this.groups[groupName], color);
            this.groups[groupName].push(legend);
        }
        this.groups[groupName].push(lineSeries);
    }

    get(groupName: string): LineSeries[] {
        return this.groups[groupName];
    }

    clearAll(): void {
        Object.keys(this.groups).forEach((groupName) => {
            this.groups[groupName][0].events.disable();
        });
        this.groups = {};
        this.groupColors = {};
    }

    getAll(): LineSeries[] {
        let allSeries = [];
        Object.keys(this.groups).forEach((groupName) => {
            allSeries = allSeries.concat(this.groups[groupName]);
        });

        return allSeries;
    }

    removeChartLine(chartLine: ChartLine): LineSeries[] {
        const removedSeries: LineSeries[] = [];
        if (!this.groups[chartLine.description]) {
            return [];
        }
        this.groups[chartLine.description] = this.groups[chartLine.description].filter((series) => {
            const seriesYAxesValueIncludesChartLineValue = series.dataFields.valueY.includes(chartLine.value);
            const hasLegendItemTooltipAChartLineDescription =
                series.dummyData && series.dummyData.legendTooltipDescription === chartLine.description;
            if (seriesYAxesValueIncludesChartLineValue || hasLegendItemTooltipAChartLineDescription) {
                series.events.disable();
                series.dispose();
                removedSeries.push(series);
                return false;
            }
            return true;
        });
        this.removeGroup(chartLine.description);
        return removedSeries;
    }

    removeSeries(identifier: string): LineSeries[] {
        const seriesToRemove: LineSeries[] = [];
        Object.keys(this.groups).forEach((groupName) => {
            const indexesToRemove = [];
            this.groups[groupName].forEach((series, index) => {
                if (series.dataFields.valueY.includes(identifier)) {
                    series.events.disable();
                    series.dispose();
                    seriesToRemove.push(series);
                    indexesToRemove.unshift(index);
                }
            });
            indexesToRemove.forEach((index) => this.groups[groupName].splice(index, 1));
            if (!this.groupHasDataSeries(groupName)) {
                this.groups[groupName].forEach((series, index) => {
                    series.events.disable();
                    series.dispose();
                    seriesToRemove.push(series);
                    indexesToRemove.push(index);
                });
                this.removeEmptyGroup(groupName);
            }
        });

        return seriesToRemove;
    }

    groupHasDataSeries(groupName: string): boolean {
        return this.groups[groupName].some((series) => series.dataFields.valueY !== 'void');
    }

    removeEmptyGroup(groupName: string): void {
        delete this.groups[groupName];
    }

    removeGroup(groupName: string): void {
        delete this.groups[groupName];
    }
}
