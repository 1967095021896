import { IRowModel } from 'ag-grid-community/dist/lib/interfaces/iRowModel';
import { IServerSideGetRowsRequest } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource';
import IExportTransformer from '@/components/grid/api/IExportTransformer';
import { EXPORTABLE_FILE_TYPES } from '@/components/grid/api/FileApi';
import IowRequestParameters from '@/components/grid/api/iow/request/IowRequestParameters';

export default class IowExportRequestTransformer implements IExportTransformer {
    transform(model: IRowModel, fileType: EXPORTABLE_FILE_TYPES) {
        // @ts-ignore
        const storeParameters: IServerSideGetRowsRequest = model.storeParams;
        storeParameters.groupKeys = [];
        // @ts-ignore
        model.request = storeParameters;
        // @ts-ignore
        const requestTransformer = model.storeParams.datasource.api.requestTransformer;
        // @ts-ignore
        const globalFilter = model.storeParams.datasource.api.globalFilter;
        const requestParameters: IowRequestParameters = requestTransformer.transform(model, globalFilter);
        requestParameters.exportFormat = fileType.toString();
        requestParameters.includeTotal = true;

        return requestParameters;
    }
}
