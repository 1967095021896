import { classToPlain } from 'class-transformer';
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import IApiRequest from '@/components/grid/api/IApiRequest';
import IowResponse from '@/components/grid/api/iow/response/IowResponse';
import { API_URL } from '@/config';

export default class IowRequest implements IApiRequest {
    fetch(requestParams: object): Promise<IowResponse> {
        const requestService = new RequestService({
            baseUrl: API_URL,
        });
        const options: IRequestOptions = {
            domain: 'reporting-studio',
            resource: 'grid',
            action: ACTION_NAME.READ,
        };

        return requestService.post<object, IowResponse>('grid', classToPlain(requestParams, { groups: ['grid'] }), options);
    }
}
