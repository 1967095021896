import IChartLineCollection from '@/components/graph/IChartLineCollection';
import ChartLine from '@/components/graph/ChartLine';

export default abstract class CustomChartLineCollection implements IChartLineCollection {
    protected readonly chartLineCollection: IChartLineCollection;

    public constructor(chartLineCollection: IChartLineCollection) {
        this.chartLineCollection = chartLineCollection;
    }

    add(chartLine: ChartLine): void {
        return this.chartLineCollection.add(chartLine);
    }

    get(chartLine: ChartLine): ChartLine {
        return this.chartLineCollection.get(chartLine);
    }

    getAll() {
        return this.chartLineCollection.getAll();
    }

    indexOf(chartLine: ChartLine): number {
        return this.chartLineCollection.indexOf(chartLine);
    }

    remove(chartLine: ChartLine): void {
        this.chartLineCollection.remove(chartLine);
    }

    removeAll(): void {
        this.chartLineCollection.removeAll();
    }

    contains(chartLine: ChartLine): boolean {
        return this.chartLineCollection.contains(chartLine);
    }

    size(): number {
        return this.chartLineCollection.size();
    }

    watchAdd(addEvent: CallableFunction): void {
        this.chartLineCollection.watchAdd(addEvent);
    }

    watchRemove(removeEvent: CallableFunction): void {
        this.chartLineCollection.watchRemove(removeEvent);
    }
}
