import { GridApi } from 'ag-grid-community';
import store from '@/store';

export default class GridFilterService {
    applyAllFilters(gridApi: GridApi) {
        const filterInstances = store.getters.getFilterInstances;
        Object.keys(filterInstances).forEach((columnKey) => {
            const filterInstance = filterInstances[columnKey];
            this.updateChildFilterModels(filterInstance);
        });
        gridApi.onFilterChanged();
        store.commit('clearModifiedFilterInstances');
    }

    resetAllFilters(gridApi: GridApi) {
        gridApi.setFilterModel(null);
    }

    private updateChildFilterModels(filterInstance) {
        Object.keys(filterInstance).forEach((index) => {
            const childFilterInstance = filterInstance[index];
            const filterModel = this.getFilterModel(childFilterInstance);
            childFilterInstance.setModel(filterModel);
        });
    }

    private getFilterModel(childFilterInstance) {
        let filterModel = childFilterInstance.getModel();
        if (childFilterInstance.getModelFromUi) {
            filterModel = childFilterInstance.getModelFromUi();
        }
        return filterModel;
    }
}
