import Vuex from 'vuex';
import Vue from 'vue';
import RequestChain from '@/components/graph/api/RequestChain';

Vue.use(Vuex);

const storeConfig = {
    state: {
        requestChains: [],
    },
    mutations: {
        addRequestChain(state, requestChain: RequestChain) {
            state.requestChains.push(requestChain);
        },
        rejectCurrentRequestChain(state) {
            while (state.requestChains.length) {
                const currentRequestChain = state.requestChains.pop();
                currentRequestChain.reject();
            }
        },
    },
    actions: {
        async resolveRequestChain({ state, commit, dispatch }, requestChain: RequestChain) {
            commit('addRequestChain', requestChain);
            return requestChain.resolve();
        },
    },
    getters: {},
};

export default new Vuex.Store(storeConfig);
