<template>
    <div class="graph-full">
        <div class="graph-container">
            <div class="reset-line-selection">
                <SrButton v-if="chartLinesCollection.size()" elevation="1" type="delete" @click="resetLineSelection">
                    Reset Line Selection
                </SrButton>
            </div>
            <div id="reporting-graph-legend" ref="reportingGraphLegend" />
            <div ref="reportingGraph" class="reporting-graph" />
            <div class="options">
                <div class="metric-selection">
                    <SrSelect
                        v-model="selectedFirstMetric"
                        prepend-inner-icon="mdi-window-minimize"
                        :items="firstSelectableMetrics"
                        placeholder="Please Select..."
                        label="First Metric"
                    />
                    <SrSelect
                        v-model="selectedSecondMetric"
                        prepend-inner-icon="mdi-dots-horizontal"
                        class="second-metric"
                        :items="secondSelectableMetrics"
                        :clearable="true"
                        placeholder="Please Select..."
                        label="Second Metric"
                        @click:clear="clearSecondMetric()"
                    />
                </div>
                <div class="granularity-selection">
                    <SrSelect
                        v-model="selectedGranularity"
                        :items="selectableGranularity"
                        label="Granularity"
                        @change="clearTimeShift()"
                    />
                </div>
                <div class="time-shift">
                    <SrInput
                        v-model="timeShift"
                        :placeholder="timeShiftUnit.unit"
                        label="Time Shift"
                        :hint="timeShiftUnit.unit"
                        :clearable="true"
                        :rules="[rules.timeShift]"
                        show-is-valid-indicator
                        @click:clear="clearTimeShift()"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import './index.scss';
import { SrButton, SrInput, SrSelect } from '@ads/design-system';
import { color, ColorSet } from '@amcharts/amcharts4/core';
import VueStoreFilter from '@/components/grid/VueStoreFilter';
import IowComponentFactory from '@/components/graph/api/iow/IowComponentFactory';
import GraphParameters from '@/components/graph/api/GraphParameters';
import defaultMetrics from '@/components/grid/api/iow/defaultMetrics';
import Chart from '@/components/graph/Chart';
import ColoredChartLineCollection from '@/components/graph/ColoredChartLineCollection';
import ToggleChartLineCollection from '@/components/graph/ToggleChartLineCollection';
import SimpleChartLineCollection from '@/components/graph/SimpleChartLineCollection';
import LimitedFifoChartLineCollection from '@/components/graph/LimitedFifoChartLineCollection';
import { granularities } from '@/components/graph/api/iow/timeGranularity';
import TimeShiftConfigFactory from '@/components/graph/TimeShiftConfigFactory';
import store from '@/store';

export default {
    name: 'SrGraph',
    components: {
        SrSelect,
        SrButton,
        SrInput,
    },
    props: {
        activeDimensions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            iowComponentFactory: new IowComponentFactory(),
            selectableGranularity: granularities,
            selectedGranularity: null,
            selectedSecondMetric: null,
            selectedFirstMetric: null,
            selectableMetrics: [],
            metricLabels: {},
            chart: null,
            timeShift: null,
            timeShiftConfigFactory: new TimeShiftConfigFactory(),
            rules: {
                timeShift(value) {
                    return isNaN(value) ? 'Input has to be a number' : true;
                },
            },
            chartLinesCollection: this.initChartLineCollection(),
        };
    },
    computed: {
        firstSelectableMetrics() {
            return this.selectableMetrics.filter((metric) => metric.value !== this.selectedSecondMetric);
        },
        secondSelectableMetrics() {
            return this.selectableMetrics.filter((metric) => metric.value !== this.selectedFirstMetric);
        },
        timeShiftUnit() {
            return this.timeShiftConfigFactory.create(this.timeShift, this.selectedGranularity);
        },
    },
    watch: {
        selectedFirstMetric: {
            handler(value) {
                const browserHistoryState = store.getters['historyStore/historyState'];
                browserHistoryState.firstMetric = value;
            },
        },
        selectedSecondMetric: {
            handler(value) {
                const browserHistoryState = store.getters['historyStore/historyState'];
                browserHistoryState.secondMetric = value;
            },
        },
        selectedGranularity: {
            handler(value) {
                const browserHistoryState = store.getters['historyStore/historyState'];
                browserHistoryState.granularity = value;
            },
        },
        timeShift: {
            handler(value) {
                const browserHistoryState = store.getters['historyStore/historyState'];
                browserHistoryState.timeShift = value;
            },
        },
    },
    mounted() {
        const metricLabels = {};
        const metricList = defaultMetrics.reduce((accumulator, metric) => {
            accumulator.push(metric.field);
            metricLabels[metric.field] = metric.headerName;
            return accumulator;
        }, []);
        this.metricLabels = metricLabels;

        this.selectableMetrics = Object.keys(this.metricLabels).map((metricValue) => ({
            label: this.metricLabels[metricValue],
            value: metricValue,
        }));

        const browserHistoryState = store.getters['historyStore/historyState'];
        this.selectedFirstMetric = browserHistoryState.firstMetric || metricList[0];
        this.selectedSecondMetric = browserHistoryState.secondMetric || this.selectedSecondMetric;
        this.selectedGranularity = browserHistoryState.granularity || this.selectableGranularity[1].value;
        this.timeShift = browserHistoryState.timeShift || this.timeShift;

        this.createGraph();
    },
    beforeDestroy() {
        this.chart.dispose();
    },
    methods: {
        initChartLineCollection() {
            const simpleChartLineCollection = new SimpleChartLineCollection();
            const limitedFifoChartLineCollection = new LimitedFifoChartLineCollection(simpleChartLineCollection, 3);
            const toggleChartLineCollection = new ToggleChartLineCollection(limitedFifoChartLineCollection);
            const colorSet = new ColorSet();
            colorSet.list = [color('#FF0000'), color('#00003C'), color('#149436')];
            colorSet.step = 1;
            colorSet.reuse = true;
            return new ColoredChartLineCollection(toggleChartLineCollection, colorSet);
        },
        createGraph() {
            const api = this.iowComponentFactory.createMultiSyncApi(new VueStoreFilter());
            this.chart = new Chart(this.$refs.reportingGraph, api, this.metricLabels);
        },
        updateGraph(filterModel = null, activeDimensions = this.activeDimensions) {
            const graphParameters = new GraphParameters();
            graphParameters.metrics = [this.selectedFirstMetric, this.selectedSecondMetric].filter((metric) => metric);
            graphParameters.activeDimensions = activeDimensions;
            graphParameters.filterModel = filterModel;
            graphParameters.chartLines = this.chartLinesCollection.getAll();
            graphParameters.responseCollection = this.iowComponentFactory.createResponseCollection();
            graphParameters.granularity = this.selectedGranularity;
            graphParameters.timeShift = this.timeShiftUnit.days;
            graphParameters.originalTimeShift = this.timeShift;
            this.chart.update(graphParameters);
            this.updateGraphParameters();
        },
        getChartLineCollection() {
            return this.chartLinesCollection;
        },
        addChartLine(chartLine) {
            this.chartLinesCollection.add(chartLine);
        },
        removeLineSeries(chartLine) {
            this.chart.removeLineSeries(chartLine);
        },
        resetLineSelection() {
            this.chartLinesCollection.getAll().forEach((chartLine) => {
                this.chart.removeLineSeries(chartLine);
            });
            this.chartLinesCollection.removeAll();
            this.$emit('redrawRows');
        },
        toggleTotalLegend() {
            this.chart.toggleTotalLegend();
        },
        clearSecondMetric() {
            this.chart.removeMetric(this.selectedSecondMetric);
        },
        clearTimeShift() {
            this.chart.removeTimeShift();
            this.timeShift = null;
        },
        updateGraphParameters() {
            const browserHistory = store.getters['historyStore/browserHistory'];
            const browserHistoryState = store.getters['historyStore/historyState'];
            browserHistory.updateGraphParameters(browserHistoryState);
        },
    },
};
</script>
