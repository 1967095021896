import { Expose } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';

enum ORIENTATION {
    left = 'left',
    right = 'right',
}

export default class PinnedColumn {
    @Expose()
    name: string;

    @Expose()
    orientation: ORIENTATION;
}

export { ORIENTATION };
