export default class Dimension {
    private _id: string;

    private _description: string;

    static of(id: string, description = '') {
        const dimension = new Dimension();
        dimension._id = id;
        dimension._description = description.trim();
        return dimension;
    }

    compare(dimension: Dimension) {
        if (!dimension) {
            return false;
        }
        return this._id === dimension._id && this._description === dimension._description;
    }

    get id() {
        return this._id;
    }

    get description() {
        return this._description;
    }
}
