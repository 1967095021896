import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-community';
import IApi from '@/components/grid/api/IApi';
import IApiResult from '@/components/grid/api/IApiResult';
import BrowserHistoryState from '@/store/BrowserHistoryState';
import store from '@/store';

const maxRetries = 3;

export default class ApiDatasource implements IServerSideDatasource {
    private readonly api: IApi;

    private retryCount = 0;

    constructor(api: IApi) {
        this.api = api;
    }

    getRows(params: IServerSideGetRowsParams): void {
        const browserState: BrowserHistoryState = store.getters['historyStore/historyState'];
        const hasRequestFiltersBeenSet = Object.keys(params.request.filterModel).length > 0;
        const hasBrowserFiltersBeenSet = browserState.filters && Object.keys(browserState.filters).length > 0;
        const isBrowserFilterOutOfSync = !hasRequestFiltersBeenSet && hasBrowserFiltersBeenSet;
        if (isBrowserFilterOutOfSync) {
            params.fail();
            return;
        }
        if (!params.request.groupKeys.length) {
            params.api.setPinnedTopRowData([]);
        }
        this.api
            .getData(params)
            .then((apiResult: IApiResult) => {
                this.retryCount = 0;
                if (!params.request.groupKeys.length && apiResult.totalRows) {
                    params.api.setPinnedTopRowData(apiResult.totalRows);
                }
                params.success(apiResult.rows);
                params.columnApi.autoSizeAllColumns();
            })
            .catch(() => {
                params.fail();
                if (this.retryCount < maxRetries) {
                    ++this.retryCount;
                    params.api.retryServerSideLoads();
                }
            });
    }
}

export { maxRetries };
