import { Expose } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';
import IowResponseRow from '@/components/grid/api/iow/response/IowResponseRow';

export default class IowResponseAdditions {
    @Expose({ name: 'confidence_range' })
    confidenceRange: number;

    @Expose()
    data: IowResponseRow[];

    @Expose({ name: 'key_count' })
    keyCount: number;
}
