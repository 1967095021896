<template>
    <div class="reporting-studio-mf" data-app>
        <VApp id="microfrontend-application-container">
            <SrAppContainer class="gray-lightest reporting-studio">
                <template #sidebar>
                    <SideNavigation />
                </template>
                <template #content>
                    <div class="reporting-content">
                        <div class="date-range-container">
                            <div class="sr-date-range-picker-wrapper">
                                <SrDateRangePicker
                                    ref="dateRangePicker"
                                    v-model="dateRange"
                                    :selected-range-config="selectedDateRangeConfig"
                                    :date-range-configs="dateRangeConfigs"
                                    :last-n-days-range-configs="lastNDaysRangeConfigs"
                                    :start-date="startDate"
                                    :end-date="endDate"
                                    :timezone-in-hours="timezone"
                                    :enable-timezones="true"
                                    :masks="masks"
                                    @configChange="updateSelectedDateRangeConfig"
                                />
                                <div class="pl-4 copy-button-container">
                                    <SrButton class="copy-to-clipboard-button sr-btn-light h-100" @click="copyToClipboard">
                                        Copy URL
                                    </SrButton>
                                </div>
                            </div>
                            <div class="reporting-buttons">
                                <SrButton class="apply-reporting-configuration" type="secondary" @click="apply"> Apply </SrButton>
                            </div>
                        </div>
                        <SrGraph
                            v-if="dateRange.start"
                            ref="reportingStudioGraph"
                            :active-dimensions="activeDimensions"
                            @redrawRows="redrawRows"
                        />
                        <SrGrid :custom-grid-options="gridOptions" theme="ag-theme-balham" />
                    </div>
                </template>
            </SrAppContainer>
        </VApp>
    </div>
</template>
<script>
import { ZonedDateRange, SrAppContainer, SrButton, SrDateRangePicker } from '@ads/design-system';
import './index.scss';
import Vue from 'vue';
import { subDays } from 'date-fns';
import SideNavigation from '@/components/navigation/SideNavigation';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import gridOptions from '@/components/grid/gridOptions';
import SrGrid from '@/components/grid/SrGrid';
import SrGraph from '@/components/graph/SrGraph';
import GridFilterService from '@/components/grid/GridFilterService';
import ColumnDefinitionService from '@/components/grid/ColumnDefinitionService';
import store from '@/store';
import { dateRangeConfigs, lastNDaysRangeConfigs } from '@/rangeConfigs';

export default {
    name: 'App',
    components: {
        SrGraph,
        SrAppContainer,
        SideNavigation,
        SrGrid,
        SrDateRangePicker,
        SrButton,
    },
    data() {
        const currentDate = new Date();
        let startDate = subDays(currentDate, 14);
        let endDate = subDays(currentDate, 1);

        const browserHistoryState = store.getters['historyStore/historyState'];
        const timezone = browserHistoryState.timezone !== undefined ? Number(browserHistoryState.timezone) : 2;

        if (browserHistoryState.dateFrom && browserHistoryState.dateTo) {
            startDate = new Date(browserHistoryState.dateFrom);
            endDate = new Date(browserHistoryState.dateTo);
        }

        return {
            showSidebar: true,
            gridOptions,
            lastNDaysRangeConfigs,
            dateRangeConfigs,
            masks: {
                input: 'YYYY-MM-DD',
            },
            dateRange: new ZonedDateRange('yyyy-MM-dd'),
            selectedDateRangeConfig: null,
            startDate,
            endDate,
            timezone,
            gridFilterService: new GridFilterService(),
            columnDefinitionService: new ColumnDefinitionService(),
            refs: this.$refs,
        };
    },
    computed: {
        activeDimensions() {
            if (this.gridOptions.columnApi) {
                return this.columnDefinitionService.getActiveDimensions(this.gridOptions.columnApi);
            }
            return [];
        },
        browserHistoryState() {
            return store.getters['historyStore/historyState'];
        },
        dateRangeConfigsKeys() {
            return this.dateRangeConfigs.map((config) => config.key);
        },
        lastNDaysRangeConfigsValues() {
            return this.lastNDaysRangeConfigs.map((config) => config.value);
        },
    },
    watch: {
        dateRange: {
            handler(newDateRange) {
                const formattedStartDate = newDateRange.getFormattedStart();
                const formattedEndDate = newDateRange.getFormattedEnd();

                this.$store.commit('setStartDate', formattedStartDate);
                this.$store.commit('setEndDate', formattedEndDate);
                this.$store.commit('setTimezone', newDateRange.timezoneInHours);

                this.browserHistoryState.dateFrom = formattedStartDate;
                this.browserHistoryState.dateTo = formattedEndDate;
                this.browserHistoryState.timezone = newDateRange.timezoneInHours;
            },
            deep: true,
        },
        'refs.reportingStudioGraph': {
            handler(srGraph) {
                this.$store.commit('setGraphReference', srGraph);
            },
        },
    },
    created() {
        Vue.set(this.refs, 'reportingStudioGraph', {});
    },
    mounted() {
        this.applyDateRangeSelection();
    },
    methods: {
        apply() {
            this.gridFilterService.applyAllFilters(this.gridOptions.api);
        },
        redrawRows() {
            this.gridOptions.api.redrawRows();
        },
        copyToClipboard() {
            const url = window.location.href;
            navigator.clipboard.writeText(url);
        },
        applyDateRangeSelection() {
            if (this.browserHistoryState.dateRangeSelection == null) {
                return;
            }
            this.selectedDateRangeConfig = this.getConfigFromDateRangeSelection(this.browserHistoryState.dateRangeSelection);
        },
        updateSelectedDateRangeConfig(newConfig) {
            this.selectedDateRangeConfig = newConfig;
            this.browserHistoryState.dateRangeSelection = this.getDateRangeSelectionFromConfig(newConfig);
        },
        getConfigFromDateRangeSelection(dateRangeSelection) {
            if (this.isLastNDaysConfigValue(dateRangeSelection)) {
                return this.lastNDaysRangeConfigs.find((config) => config.value === Number(dateRangeSelection));
            }
            if (this.isDateRangeConfigKey(dateRangeSelection)) {
                return this.dateRangeConfigs.find((config) => config.key === dateRangeSelection);
            }
            return null;
        },
        getDateRangeSelectionFromConfig(config) {
            if (this.isLastNDaysRangeConfig(config)) {
                return config.value.toString();
            }
            if (this.isDateRangeConfig(config)) {
                return config.key;
            }
            return null;
        },
        isLastNDaysRangeConfig(config) {
            return config?.value !== undefined;
        },
        isDateRangeConfig(config) {
            return config?.key !== undefined;
        },
        isDateRangeConfigKey(key) {
            return this.dateRangeConfigsKeys.includes(key);
        },
        isLastNDaysConfigValue(numberOfDays) {
            return this.lastNDaysRangeConfigsValues.includes(Number(numberOfDays));
        },
    },
};
</script>
