import { classToPlain, plainToClass } from 'class-transformer';
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import IApiRequest from '@/components/grid/api/IApiRequest';
import IowResponse from '@/components/graph/api/iow/response/IowResponse';
import IowRequestParameters from '@/components/graph/api/iow/request/IowRequestParameters';
import { API_URL } from '@/config';

export default class IowRequest implements IApiRequest {
    fetch(requestParams: IowRequestParameters): Promise<IowResponse> {
        const requestService = new RequestService({
            baseUrl: API_URL,
        });
        const options: IRequestOptions = {
            domain: 'reporting-studio',
            resource: 'graph',
            action: ACTION_NAME.READ,
        };
        const body = {
            ...classToPlain(requestParams),
        };

        return new Promise((resolve) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            requestService.post<object, IowResponse>('graph', body, options).then((response) => {
                resolve(plainToClass(IowResponse, response));
            });
        });
    }
}
