import MetricPoint, { PlainPoint } from '@/components/graph/api/iow/response/MetricPoint';
import SingleIowResponse from '@/components/graph/api/iow/response/SingleIowResponse';
import GraphParameters from '@/components/graph/api/GraphParameters';
import IowResponseRow from '@/components/graph/api/iow/response/IowResponseRow';

export default class ChartLineMetricPointTransformer {
    transformTimeShiftedResponse(
        response: SingleIowResponse,
        graphParameters: GraphParameters,
        originalResponse: SingleIowResponse,
    ): { [property: string]: PlainPoint[] } {
        return response.response.rows.reduce((chartLineMetricPoints, row, index) => {
            let relatedCategory: string;
            let timeShiftedRow: IowResponseRow;
            if (graphParameters.granularity === 'granularity_hour') {
                const shiftedIndex = graphParameters.calculateTimeShiftOffsetForHours(index);
                if (!response.getRowAtIndex(shiftedIndex)) {
                    timeShiftedRow = originalResponse.getRowAtIndex(index - graphParameters.originalTimeShift);
                    const originalRow = this.getOriginalRowForTimeShift(originalResponse, index);
                    relatedCategory = originalRow.time;
                } else {
                    timeShiftedRow = response.getRowAtIndex(shiftedIndex);
                    relatedCategory = originalResponse.getRowAtIndex(index).time;
                }
            } else {
                timeShiftedRow = row;
                const originalRow = this.getOriginalRowForTimeShift(originalResponse, index);
                relatedCategory = originalRow.time;
            }

            const chartPoints = MetricPoint.of(
                graphParameters,
                response,
                timeShiftedRow,
                relatedCategory,
            ).toSeparatePlainPoints();
            this.populateChartLineWithPoints(chartLineMetricPoints, chartPoints);
            return chartLineMetricPoints;
        }, {});
    }

    transformOriginalResponse(
        response: SingleIowResponse,
        graphParameters: GraphParameters,
    ): { [property: string]: PlainPoint[] } {
        return response.response.rows.reduce((chartLineMetricPoints, row) => {
            const chartPoints = MetricPoint.of(graphParameters, response, row).toSeparatePlainPoints();
            this.populateChartLineWithPoints(chartLineMetricPoints, chartPoints);
            return chartLineMetricPoints;
        }, {});
    }

    private populateChartLineWithPoints(
        chartLineMetricPoints: { [property: string]: PlainPoint[] },
        metricPoints: { [property: string]: PlainPoint[] },
    ): void {
        Object.entries(metricPoints).forEach(([metricName, plainPoints]) => {
            if (!chartLineMetricPoints[metricName]) {
                chartLineMetricPoints[metricName] = [];
            }
            chartLineMetricPoints[metricName] = chartLineMetricPoints[metricName].concat(plainPoints);
        });
    }

    private getOriginalRowForTimeShift(originalResponse: SingleIowResponse, index: number): IowResponseRow {
        return originalResponse.response.rows[index];
    }
}
