<template>
    <div id="grid-total-line" class="ag-cell-label-container" @click="toggleTotalLegend">
        <div class="ag-header-cell-label">-</div>
    </div>
</template>

<script>
import store from '@/store';

export default {
    name: 'GraphCellRenderer',
    data() {
        return {};
    },
    methods: {
        toggleTotalLegend() {
            store.getters.getGraphReference.toggleTotalLegend();
        },
    },
};
</script>
