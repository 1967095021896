import { Expose, Type } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';
import IowResponseTotalRow from '@/components/grid/api/iow/response/IowResponseTotalRow';

export default class IowResponseTotal {
    @Expose()
    @Type(() => IowResponseTotalRow)
    data: IowResponseTotalRow[];

    @Expose({ name: 'confidence_range' })
    confidenceRange: number;

    @Expose()
    dates: string[];

    @Expose({ name: 'records_found' })
    recordsFound: number;
}
