import { LineSeries } from '@amcharts/amcharts4/charts';
import { color } from '@amcharts/amcharts4/core';
import { Color } from '@amcharts/amcharts4/.internal/core/utils/Color';
import LineSeriesAction from '@/components/graph/manipulation/granularity/LineSeriesAction';
import LineSeriesOptions from '@/components/graph/LineSeriesOptions';

export default class LineSeriesService {
    /**
     * Creates a LineSeries itself from ChartSeries object that represents the
     * properties of the LineSeries object.
     */
    create(lineSeriesOptions: LineSeriesOptions): LineSeries {
        const series = new LineSeries();
        series.name = lineSeriesOptions.getMetricLabel();
        series.showOnInit = false;
        series.zIndex = 100;
        series.data = lineSeriesOptions.getData();
        series.dataFields.categoryX = 'category';
        series.dataFields.valueY = lineSeriesOptions.getIdentifier();
        series.tooltipText = `{originCategory}: [b]{valueY}[/]- ${lineSeriesOptions.getMetricLabel()}`;
        series.adapter.add('tooltipText', () => `[bold]{originCategory}[/]\n${series.name}: {${series.dataFields.valueY}}\n`);
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fillOpacity = 1;
        series.tooltip.background.strokeOpacity = 1;
        series.tooltip.background.opacity = lineSeriesOptions.getOpacity() * 2;
        series.tooltip.background.zIndex = 200;
        series.tooltip.fillOpacity = 1;
        series.tooltip.zIndex = 200;
        series.tooltip.background.fill = series.stroke;
        series.strokeDasharray = `${lineSeriesOptions.getMetricIndex() * 2},${lineSeriesOptions.getMetricIndex() * 2}`;
        series.tooltip.background.fill = color(lineSeriesOptions.getColor());
        series.stroke = color(lineSeriesOptions.getColor());
        series.opacity = lineSeriesOptions.getOpacity();
        series.dummyData = {
            opacity: lineSeriesOptions.getOpacity(),
        };
        series.hiddenInLegend = true;
        lineSeriesOptions.getYAxis().disabled = false;
        series.yAxis = lineSeriesOptions.getYAxis();
        const lineSeriesAction = new LineSeriesAction(series);
        lineSeriesAction.runOn(lineSeriesOptions.getGranularity());

        return series;
    }

    createLegend(groupName: string, groupSeries: LineSeries[], legendColor: Color) {
        const series = new LineSeries();
        series.name = groupName.replace(/(.{60})..+/, '$1…');
        series.dummyData = {
            legendTooltipDescription: groupName,
        };
        series.dataFields.categoryX = 'category';
        series.dataFields.valueY = 'void';
        series.hiddenInLegend = false;
        series.stroke = legendColor;
        series.events.on('hidden', () => {
            groupSeries.forEach((singleSeries) => {
                if (singleSeries.dataFields.valueY !== 'void') {
                    singleSeries.hide();
                }
            });
        });

        series.events.on('shown', () => {
            groupSeries.forEach((singleSeries) => {
                if (singleSeries.dataFields.valueY !== 'void') {
                    singleSeries.show();
                    singleSeries.opacity = singleSeries.dummyData.opacity;
                }
            });
        });
        if (groupName === 'Total') {
            this.createTotalLegendEvents(series);
        }

        return series;
    }

    createTotalLegendEvents(series) {
        series.events.on('hidden', () => {
            const gridTotalLineLabelElement: HTMLElement = document
                .querySelector('.ag-cell-label-container')
                .querySelector('.ag-header-cell-label');
            gridTotalLineLabelElement.style.visibility = 'hidden';
        });

        series.events.on('inited', () => {
            const gridTotalLineLabelElement: HTMLElement = document
                .querySelector('.ag-cell-label-container')
                .querySelector('.ag-header-cell-label');
            gridTotalLineLabelElement.style.visibility = 'visible';
        });

        series.events.on('shown', () => {
            const gridTotalLineLabelElement: HTMLElement = document
                .querySelector('.ag-cell-label-container')
                .querySelector('.ag-header-cell-label');
            gridTotalLineLabelElement.style.visibility = 'visible';
        });

        return series;
    }
}
