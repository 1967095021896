<template>
    <div class="reporting-grid">
        <VCard flat>
            <VCardTitle>
                <VSpacer />
                <SrMenu bottom left>
                    <template #activator="{ on, attrs }">
                        <SrButton icon v-bind="attrs" v-on="on">
                            Download File
                            <SrIcon :active="true" icon="more" size="xs" />
                        </SrButton>
                    </template>

                    <SrList>
                        <SrListItem v-for="(item, i) in downloadFiles" :key="i" class="grid-export" @click="download(item)">
                            <SrListItemIcon>
                                <SrIcon icon="file" size="xs" />
                            </SrListItemIcon>
                            <SrListItemTitle>{{ item.title }}</SrListItemTitle>
                        </SrListItem>
                    </SrList>
                </SrMenu>
            </VCardTitle>
        </VCard>
        <ag-grid-vue
            :animate-rows="true"
            :class="theme"
            :enable-browser-tooltips="true"
            :grid-options="customGridOptions"
            :suppress-agg-func-in-header="true"
            class="ag-grid"
        />
    </div>
</template>

<script>
import './index.scss';
import 'ag-grid-community/styles/ag-grid.css';

import { AgGridVue } from 'ag-grid-vue';
import { SrIcon, SrButton, SrMenu, SrList, SrListItem, SrListItemIcon, SrListItemTitle } from '@ads/design-system';
import DimensionCellRenderer from '@/components/grid/api/iow/DimensionCellRenderer';
import GraphCellRenderer from '@/components/grid/api/iow/GraphCellRenderer';
import IowExportComponentFactory from '@/components/grid/api/iow/IowExportComponentFactory';
import CustomDimensionFilter from '@/components/grid/CustomDimensionFilter';

export default {
    name: 'SrGrid',
    components: {
        AgGridVue,
        // eslint-disable-next-line vue/no-unused-components
        DimensionCellRenderer,
        // eslint-disable-next-line vue/no-unused-components
        GraphCellRenderer,
        SrIcon,
        SrMenu,
        SrButton,
        SrList,
        SrListItem,
        SrListItemIcon,
        SrListItemTitle,
        // eslint-disable-next-line vue/no-unused-components
        CustomDimensionFilter,
    },
    props: {
        customGridOptions: {
            type: Object,
            required: true,
        },
        theme: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            gridOptions: null,
            gridApi: null,
            gridColumnApi: null,
            componentFactory: new IowExportComponentFactory(),
            downloadFiles: [
                { title: 'Excel', format: 'xlsx' },
                { title: 'CSV', format: 'csv' },
            ],
        };
    },
    beforeMount() {
        this.gridOptions = this.customGridOptions;
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
    },
    methods: {
        async download(file) {
            const model = this.gridApi.getModel();
            const api = this.componentFactory.createApi(model);
            const blob = await api.get(file.format);
            const encodedUri = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('href', encodedUri);
            const date = new Date();
            const time = date.toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit',
            });
            const formattedTime = time.replace(':', '-');
            const reportFilename = `report-${date.toJSON().slice(0, 10)} ${formattedTime}.${file.format}`;
            a.setAttribute('download', reportFilename);
            document.body.appendChild(a);
            a.click();
            a.remove();
        },
    },
};
</script>
