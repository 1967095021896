import { Expose } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';

export default class IowDimensionFilter {
    @Expose()
    match: string;

    @Expose()
    value: string[];

    @Expose({ name: 'case_insensitive' })
    caseInsensitive?: number;

    @Expose({ name: 'search_mappings' })
    searchMappings?: number;

    @Expose()
    name: string;
}
