import IApi from '@/components/grid/api/IApi';
import IApiRequest from '@/components/grid/api/IApiRequest';
import IGlobalFilter from '@/components/grid/IGlobalFilter';
import IRequestParameterTransformer from '@/components/graph/api/IRequestParameterTransformer';
import GraphParameters from '@/components/graph/api/GraphParameters';
import IResponseTransformer from '@/components/graph/api/IResponseTransformer';
import IMultiResponseTransformer from '@/components/graph/api/IMultiResponseTransformer';
import RequestQueue from '@/store/RequestQueue';
import RequestChain from '@/components/graph/api/RequestChain';
import IowRequestParameters from '@/components/graph/api/iow/request/IowRequestParameters';

export default class MultiSyncApi implements IApi {
    private readonly apiRequest: IApiRequest;

    private readonly requestParameterTransformer: IRequestParameterTransformer;

    private readonly responseTransformer: IResponseTransformer;

    private readonly multiResponseTransformer: IMultiResponseTransformer;

    private readonly globalFilter: IGlobalFilter;

    constructor(
        apiRequest: IApiRequest,
        requestTransformer: IRequestParameterTransformer,
        responseTransformer: IResponseTransformer,
        multiResponseTransformer: IMultiResponseTransformer,
        globalFilter: IGlobalFilter,
    ) {
        this.apiRequest = apiRequest;
        this.requestParameterTransformer = requestTransformer;
        this.responseTransformer = responseTransformer;
        this.multiResponseTransformer = multiResponseTransformer;
        this.globalFilter = globalFilter;
    }

    async getData(graphParameters: GraphParameters): Promise<any> {
        const iowRequestsParameters: IowRequestParameters[] = this.requestParameterTransformer.transform(
            graphParameters,
            this.globalFilter,
        );
        RequestQueue.commit('rejectCurrentRequestChain');
        const requestChain = new RequestChain(this.apiRequest, this.responseTransformer, graphParameters, iowRequestsParameters);

        const resolvedRequest: GraphParameters = await RequestQueue.dispatch('resolveRequestChain', requestChain);
        if (resolvedRequest.responseCollection.isEmpty()) {
            return [];
        }

        return this.multiResponseTransformer.transform(resolvedRequest);
    }
}
