import DecimalFormatter from '@/components/grid/DecimalFormatter';
import PercentFormatter from '@/components/grid/PercentFormatter';
import CurrencyFormatter from '@/components/grid/CurrencyFormatter';

export default class FormatterFactory {
    createDecimalFormatter() {
        return new DecimalFormatter().format.bind({
            locale: 'en-US',
            options: {
                style: 'decimal',
            },
        });
    }

    createPercentFormatter() {
        return new PercentFormatter().format.bind({
            locale: 'en-US',
            options: {
                style: 'percent',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            },
        });
    }

    createCurrencyFormatter() {
        return new CurrencyFormatter().format.bind({
            locale: 'en-US',
            options: {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'USD',
            },
        });
    }

    createUnknownCurrencyFormatter() {
        return new CurrencyFormatter().format.bind({
            locale: 'en-US',
            options: {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            },
        });
    }
}
