import IGlobalFilter from '@/components/grid/IGlobalFilter';
import store from '@/store';

export default class VueStoreFilter implements IGlobalFilter {
    getEndDate(): string {
        return store.getters.getEndDate;
    }

    getStartDate(): string {
        return store.getters.getStartDate;
    }

    getTimezone(): number {
        return store.getters.getTimezone;
    }
}
