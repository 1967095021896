import ChartLine from '@/components/graph/ChartLine';
import CustomChartLineCollection from '@/components/graph/CustomChartLineCollection';

export default class ToggleChartLineCollection extends CustomChartLineCollection {
    add(chartLine: ChartLine): void {
        if (this.contains(chartLine)) {
            this.chartLineCollection.remove(chartLine);
            return;
        }
        this.chartLineCollection.add(chartLine);
    }
}
