export default class DimensionIdTransformer {
    transform(name: string | string[], index: number = 0): string {
        if (name instanceof Array) {
            name = this.getNameString(name, index);
        }
        if (!name) {
            return null;
        }

        return name;
    }

    private getNameString(names: string[], index: number) {
        return names[index];
    }
}
