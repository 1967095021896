import { DateRangeConfig, DATE_RANGE_CONFIG_KEY } from '@ads/design-system';

const dateRangeConfigs: DateRangeConfig = [
    { label: 'Last week', key: DATE_RANGE_CONFIG_KEY.lastWeek },
    { label: 'Last month', key: DATE_RANGE_CONFIG_KEY.lastMonth },
];

const lastNDaysRangeConfigs = [
    { text: '90 days', value: 90 },
    { text: '60 days', value: 60 },
    { text: '30 days', value: 30 },
    { text: '14 days', value: 14 },
    { text: '7 days', value: 7 },
    { text: '3 days', value: 3 },
];

export { dateRangeConfigs, lastNDaysRangeConfigs };
