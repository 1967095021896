import IMultiResponseTransformer from '@/components/graph/api/IMultiResponseTransformer';
import GraphParameters from '@/components/graph/api/GraphParameters';
import SingleIowResponse from '@/components/graph/api/iow/response/SingleIowResponse';
import ChartLineMetricPointTransformer from '@/components/graph/api/iow/response/ChartLineMetricPointTransformer';

export default class IowMultiResponseSplitTransformer implements IMultiResponseTransformer {
    transform(graphParameters: GraphParameters): any[] {
        const responses = graphParameters.responseCollection.getAll();

        return responses.reduce((chartLines, response: SingleIowResponse) => {
            if (!response.response) {
                return chartLines;
            }

            let chartLine;
            if (response.isTimeShift) {
                const originalResponse = this.getOriginalResponseForTimeshift(responses, response);
                chartLine = this.buildChartLineMetricPoints(graphParameters, response, originalResponse);
            } else {
                chartLine = this.buildChartLineMetricPoints(graphParameters, response);
            }

            chartLines = { ...chartLines, ...chartLine };
            return chartLines;
        }, {});
    }

    private buildChartLineMetricPoints(
        graphParameters: GraphParameters,
        response: SingleIowResponse,
        originalResponse?: SingleIowResponse,
    ) {
        if (originalResponse) {
            return new ChartLineMetricPointTransformer().transformTimeShiftedResponse(
                response,
                graphParameters,
                originalResponse,
            );
        }

        return new ChartLineMetricPointTransformer().transformOriginalResponse(response, graphParameters);
    }

    private getOriginalResponseForTimeshift(responses: any[], response: SingleIowResponse) {
        return responses.find((currentResponse) => currentResponse.metric === response.metric);
    }
}
