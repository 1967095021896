export default class DelayedGroupTrigger {
    private timeout = null;

    addEvent(delay: number, event: CallableFunction): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => event(), delay);
    }
}
