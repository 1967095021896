import { plainToClass } from 'class-transformer';
import IowDimensionFilter from '@/components/grid/api/iow/request/IowDimensionFilter';
import { dimensions } from '@/components/grid/api/iow/defaultDimensions';
import IowMetricFilter from '@/components/grid/api/iow/request/IowMetricFilter';

export default class IowFilterGenerator {
    generate(columnKey: string, predicate: string, filterValue): IowDimensionFilter {
        if (this.isDimension(columnKey)) {
            return plainToClass(IowDimensionFilter, {
                match: predicate,
                value: filterValue,
                name: columnKey,
                case_insensitive: 1,
                search_mappings: 1,
            });
        }
        return plainToClass(IowMetricFilter, {
            match: predicate,
            value: filterValue,
            name: columnKey,
        });
    }

    private isDimension(columnKey: string) {
        return dimensions.find((dimension) => dimension.showRowGroup === columnKey);
    }
}
