import { Expose } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';

export default class IowResponseRow {
    @Expose()
    total: number;

    @Expose({ name: '__time__' })
    time: string;
}
