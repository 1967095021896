import Vuex from 'vuex';
import Vue from 'vue';
import historyStore from '@/store/historyStore';
import ColumnDefinitionService from '@/components/grid/ColumnDefinitionService';

Vue.use(Vuex);

const globalStore = {
    state: {
        startDate: null,
        endDate: null,
        timezone: null,
        filterInstances: {},
        graphReference: null,
        dimensions: null,
        metrics: null,
        movedColumn: null,
    },
    mutations: {
        setStartDate(state, date) {
            state.startDate = date;
        },
        setEndDate(state, date) {
            state.endDate = date;
        },
        setTimezone(state, timezone) {
            state.timezone = timezone;
        },
        setDefaultDimensions(state, dimensions) {
            state.dimensions = dimensions;
        },
        setDefaultMetrics(state, metrics) {
            state.metrics = metrics;
        },
        setMovedColumn(state, movedColumn) {
            state.movedColumn = movedColumn;
        },
        addModifiedFilterInstance(state, { columnKey, index, filterInstance }) {
            if (!state.filterInstances[columnKey]) {
                state.filterInstances[columnKey] = {};
            }
            state.filterInstances[columnKey][index.toString()] = filterInstance;
        },
        clearModifiedFilterInstances(state) {
            state.filterInstances = {};
        },
        setGraphReference(state, graphReference) {
            state.graphReference = graphReference;
        },
        clearGraphReference(state) {
            state.graphReference = null;
        },
        updateGraph(state, { api, columnApi }) {
            const columnDefinitionService = new ColumnDefinitionService();
            const activeDimensions = columnDefinitionService.getActiveDimensions(columnApi);
            const filterModel = api.getFilterModel();
            state.graphReference.updateGraph(filterModel, activeDimensions);
        },
    },
    actions: {},
    getters: {
        getStartDate(state) {
            return state.startDate;
        },
        getEndDate(state) {
            return state.endDate;
        },
        getTimezone(state) {
            return state.timezone;
        },
        getDefaultDimensions(state) {
            return state.dimensions;
        },
        getDefaultMetrics(state) {
            return state.metrics;
        },
        getMovedColumn(state) {
            return state.movedColumn;
        },
        getFilterInstances(state) {
            return state.filterInstances;
        },
        getGraphReference(state) {
            return state.graphReference;
        },
    },
};

export default new Vuex.Store({
    modules: {
        globalStore,
        historyStore,
    },
});
