import { IDashboard } from '@/dashboards/IDashboard';
import DynamicDashboardService from '@/dashboards/DynamicDashboardService';
import StaticDashboardService from '@/dashboards/StaticDashboardService';

export default class SideNavigationViewModel {
    private readonly staticDashboardsService: StaticDashboardService;

    private readonly dynamicDashboardService: DynamicDashboardService;

    private dynamicDashboards: IDashboard[] = [];

    constructor(staticDashboardService: StaticDashboardService, dynamicDashboardService: DynamicDashboardService) {
        this.staticDashboardsService = staticDashboardService;
        this.dynamicDashboardService = dynamicDashboardService;
    }

    getStaticDashboards(): IDashboard[] {
        return this.staticDashboardsService.get();
    }

    async loadDynamicDashboards(): Promise<IDashboard[]> {
        try {
            this.dynamicDashboards = await this.dynamicDashboardService.load();
        } catch (error) {
            console.error('Loading dynamic dashboards failed.', error.message);
        }
        return this.dynamicDashboards;
    }

    getNavigationItems(): IDashboard[] {
        const dynamicDashboards = [...this.dynamicDashboards];
        dynamicDashboards.splice(1, 0, ...this.getStaticDashboards());

        return dynamicDashboards;
    }
}
