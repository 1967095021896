import GraphParameters from '@/components/graph/api/GraphParameters';
import IowRequestParameters from '@/components/graph/api/iow/request/IowRequestParameters';
import ResponseCancelled from '@/components/graph/ResponseCancelled';
import IApiRequest from '@/components/grid/api/IApiRequest';
import IResponseTransformer from '@/components/graph/api/IResponseTransformer';

export default class RequestChain {
    private readonly graphParameters: GraphParameters;

    private readonly iowRequestsParameters: IowRequestParameters[];

    private readonly apiRequest: IApiRequest;

    private readonly responseTransformer: IResponseTransformer;

    private isRejected: boolean;

    constructor(
        apiRequest: IApiRequest,
        responseTransformer: IResponseTransformer,
        graphParameters: GraphParameters,
        iowRequestsParameters: IowRequestParameters[],
    ) {
        this.apiRequest = apiRequest;
        this.responseTransformer = responseTransformer;
        this.graphParameters = graphParameters;
        this.iowRequestsParameters = iowRequestsParameters;
    }

    async resolve(): Promise<GraphParameters> {
        for (const requestParameters of this.iowRequestsParameters) {
            // eslint-disable-next-line no-await-in-loop
            await this.createPromise(requestParameters)
                .then((response) => this.graphParameters.responseCollection.push(response))
                .catch((e) => {
                    console.error('API call to Slicer failed', e);
                });
        }
        if (this.isRejected) {
            throw new ResponseCancelled();
        }

        return this.graphParameters;
    }

    reject() {
        this.isRejected = true;
    }

    createPromise(mappedRequest) {
        return new Promise((resolve, reject) => {
            this.apiRequest
                .fetch(mappedRequest)
                .then((response) => {
                    resolve(this.responseTransformer.transform(response, mappedRequest));
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}
