import { IRowModel } from 'ag-grid-community/dist/lib/interfaces/iRowModel';
import IApiRequest from '@/components/grid/api/IApiRequest';
import IExportTransformer from '@/components/grid/api/IExportTransformer';
import BlobTransformer from '@/components/grid/BlobTransformer';

enum EXPORTABLE_FILE_TYPES {
    'xlsx',
    'csv',
}

export default class FileApi {
    private readonly model: IRowModel;

    private readonly apiRequest: IApiRequest;

    private readonly transformer: IExportTransformer;

    private readonly blobTransformer: BlobTransformer;

    constructor(model: IRowModel, apiRequest: IApiRequest, transformer: IExportTransformer, blobTransformer: BlobTransformer) {
        this.model = model;
        this.apiRequest = apiRequest;
        this.transformer = transformer;
        this.blobTransformer = blobTransformer;
    }

    static contentTypes = {
        [EXPORTABLE_FILE_TYPES.xlsx]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        [EXPORTABLE_FILE_TYPES.csv]: 'text/csv',
    };

    async get(fileType: EXPORTABLE_FILE_TYPES): Promise<Blob> {
        const requestParameters = this.transformer.transform(this.model, fileType);
        const base64Content = await this.apiRequest.fetch(requestParameters);
        return this.blobTransformer.transform(base64Content, FileApi.contentTypes[EXPORTABLE_FILE_TYPES[fileType]]);
    }
}

export { EXPORTABLE_FILE_TYPES };
