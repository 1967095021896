/* eslint-disable @typescript-eslint/naming-convention */
import { Expose, Type } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';
import { ColumnVO, SortModelItem } from 'ag-grid-community';
import IowDimensionFilter from '@/components/grid/api/iow/request/IowDimensionFilter';
import Order, { DIRECTION } from '@/components/grid/api/iow/request/Order';
import IowMetricFilter from '@/components/grid/api/iow/request/IowMetricFilter';

export default class IowRequestParameters {
    @Expose({ name: 'start_date' })
    startDate: string;

    @Expose({ name: 'end_date' })
    endDate: string;

    @Expose({ name: 'split_by' })
    splitBy: string[] = [];

    @Expose()
    timezone?: number;

    @Type(() => Order)
    @Expose({ name: 'order_by' })
    orderBy?: Order[];

    @Expose({ name: 'include_cumulative' })
    includeCumulative?: number;

    @Expose({ name: 'include_mappings' })
    includeMappings?: number = 1;

    @Type(() => IowDimensionFilter)
    @Expose()
    filters?: IowDimensionFilter[] = [];

    @Expose({ name: 'filter_template' })
    filterTemplate?: string;

    @Expose({ name: 'data_filters' })
    dataFilters?: IowMetricFilter[] = [];

    @Expose({ name: 'data_filter_template' })
    dataFilterTemplate?: string;

    @Expose({ groups: ['grid'] })
    limit?: number = -1;

    @Expose({ groups: ['grid'] })
    offset?: number;

    @Expose({ name: 'need_others' })
    needOthers?: boolean;

    @Expose({ name: 'data_fields' })
    dataFields?: string[];

    @Expose({ name: 'export_format', groups: ['export'] })
    exportFormat?: string;

    @Expose({ name: 'include_total', groups: ['export'] })
    includeTotal?: boolean;

    setMetrics(activeMetrics: string[]) {
        this.dataFields = activeMetrics;
    }

    setDimensions(activeDimensions: string[]) {
        if (activeDimensions.length) {
            this.splitBy = activeDimensions.reduce((accumulator, column) => {
                accumulator.push(column);
                return accumulator;
            }, []);
        }
    }

    setOrders(sortModel: SortModelItem[], rowGroupCols: ColumnVO[]) {
        if (!sortModel.length && rowGroupCols.length) {
            this.orderBy = [
                {
                    name: rowGroupCols[0].field,
                    direction: DIRECTION.asc,
                },
            ];
            return;
        }

        const orderBy = [];
        sortModel.forEach((sortModelItem) => {
            orderBy.push({
                name: sortModelItem.colId,
                direction: sortModelItem.sort,
            });
        });
        if (orderBy.length) {
            this.orderBy = orderBy;
        }
    }

    setGroupKeyFilters(rowGroupCols: ColumnVO[], groupKeys: string[]) {
        const filters = groupKeys.reduce((accumulator: IowDimensionFilter[], groupValue, index) => {
            const dimensionId = this.getDimensionKey(groupValue, rowGroupCols[index].field);
            accumulator.push({
                match: 'equals',
                value: [dimensionId],
                caseInsensitive: 1,
                searchMappings: 1,
                name: rowGroupCols[index].field,
            });
            return accumulator;
        }, []);
        if (filters.length) {
            this.filters = [...this.filters, ...filters];
        }
    }

    private getDimensionKey(groupValue: string, groupName: string): string {
        const dimensionKey = groupValue.split(' ')[0];
        if (dimensionKey === 'Unknown') {
            return null;
        }
        if (groupName.includes('_id')) {
            return dimensionKey;
        }

        return groupValue;
    }

    setLimitations(startRow: number, endRow: number) {
        this.limit = endRow - startRow;
        this.offset = startRow;
    }

    setFilters(filters: IowDimensionFilter[]) {
        if (filters.length) {
            this.filters = [...this.filters, ...filters];
        }
    }

    setMetricFilters(filters: IowMetricFilter[]) {
        if (filters.length) {
            this.dataFilters = filters;
        }
    }

    setMetricFilterTemplate(metricFilterTemplate: string) {
        if (metricFilterTemplate) {
            this.dataFilterTemplate = metricFilterTemplate;
        }
    }
}
