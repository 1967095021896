import { ColumnApi } from 'ag-grid-community';
import BrowserHistory from '@/store/BrowserHistory';
import URLWrapper from '@/store/URLWrapper';
import URLParameterConfig from '@/store/URLParameterConfig';
import BrowserHistoryStateConfig from '@/store/BrowserHistoryStateConfig';
import ColumnDefinitionService from '@/components/grid/ColumnDefinitionService';
import IChartLineCollection from '@/components/graph/IChartLineCollection';
import store from '@/store/index';

const urlWrapper: URLWrapper = new URLWrapper(new URLParameterConfig());
const browserHistory: BrowserHistory = new BrowserHistory(urlWrapper, new BrowserHistoryStateConfig());

export default {
    namespaced: true,
    state: {
        urlWrapper,
        browserHistory,
        browserHistoryState: browserHistory.getStateFromUrl(),
    },
    mutations: {
        updateGridParameters(state, columnApi: ColumnApi) {
            const columnDefinitionService = new ColumnDefinitionService();
            state.browserHistoryState.dimensions = columnDefinitionService.getActiveDimensions(columnApi);
            state.browserHistoryState.metrics = columnDefinitionService.getActiveMetrics(columnApi);
            state.browserHistoryState.pinning = columnDefinitionService.getPinnedColumns(columnApi);
            state.browserHistoryState.sorting = columnDefinitionService.getColumnsSorting(columnApi);
            const chartLineCollection: IChartLineCollection = store.getters.getGraphReference.getChartLineCollection();
            state.browserHistoryState.chartLines = chartLineCollection.getAll();
            state.browserHistory.updateGridParameters(state.browserHistoryState);
        },
    },
    actions: {},
    getters: {
        urlWrapper(state) {
            return state.urlWrapper;
        },
        browserHistory(state) {
            return state.browserHistory;
        },
        historyState(state) {
            return state.browserHistoryState;
        },
    },
};
