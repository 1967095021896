import IowConditionsFilterTransformer from '@/components/grid/api/iow/request/IowConditionsFilterTransformer';
import IowFilterGenerator from '@/components/grid/api/iow/request/IowFilterGenerator';

export default class IowFilterModelTransformer {
    private readonly iowConditionsFilterTransformer: IowConditionsFilterTransformer;

    private readonly iowFilterGenerator: IowFilterGenerator;

    constructor(iowConditionsFilterTransformer: IowConditionsFilterTransformer, iowFilterGenerator: IowFilterGenerator) {
        this.iowConditionsFilterTransformer = iowConditionsFilterTransformer;
        this.iowFilterGenerator = iowFilterGenerator;
    }

    transform(columnKey: string, gridFilter: any) {
        if (!gridFilter) {
            return [];
        }
        if (this.isCondition(gridFilter)) {
            return this.iowConditionsFilterTransformer.transform(columnKey, gridFilter);
        }
        if (this.isRangeFilter(gridFilter.type)) {
            const filterFrom = this.iowFilterGenerator.generate(columnKey, '>', gridFilter.filter - 0.01);
            const filterTo = this.iowFilterGenerator.generate(columnKey, '<', gridFilter.filterTo + 0.01);
            return [filterFrom, filterTo];
        }
        return [this.iowFilterGenerator.generate(columnKey, gridFilter.type, [gridFilter.filter])];
    }

    private isCondition(filter): boolean {
        // eslint-disable-next-line no-prototype-builtins
        return !filter.hasOwnProperty('filter');
    }

    private isRangeFilter(predicate: string): boolean {
        return predicate === 'inRange';
    }
}
