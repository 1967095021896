import am4themes_material from '@amcharts/amcharts4/themes/material';
import { Circle, Container, create, percent, Rectangle, ResizeButton, useTheme } from '@amcharts/amcharts4/core';
import { CategoryAxis, Legend, LineSeries, ValueAxis, XYChart, XYChartScrollbar, XYCursor } from '@amcharts/amcharts4/charts';
import { Color } from '@amcharts/amcharts4/.internal/core/utils/Color';
import ChartOverlay from '@/components/graph/ChartOverlay';
import GraphParameters from '@/components/graph/api/GraphParameters';
import MultiSyncApi from '@/components/graph/api/MultiSyncApi';
import LineSeriesGroups from '@/components/graph/LineSeriesGroups';
import LineSeriesService from '@/components/graph/LineSeriesService';
import ResponseCancelled from '@/components/graph/ResponseCancelled';
import ChartLine from '@/components/graph/ChartLine';
import XAxesAction from '@/components/graph/manipulation/granularity/XAxesAction';
import LineSeriesOptionsFactory from '@/components/graph/LineSeriesOptionsFactory';

useTheme(am4themes_material);

function resizeLegend() {
    if (this.legend) {
        document.getElementById('reporting-graph-legend').style.height = `${this.legend.contentHeight}px`;
    }
}

export default class Chart {
    private readonly chart: XYChart;

    private readonly overlay: ChartOverlay;

    private readonly api: MultiSyncApi;

    private readonly metricLabels: any;

    static TOTAL_COLOR = new Color({ r: 25, g: 118, b: 210 });

    /**
     * Holds the groups of all the LineSeries.
     * @private
     */
    private readonly lineSeriesGroups: LineSeriesGroups;

    private xAxesAction: XAxesAction;

    private xAxesAction2: XAxesAction;

    constructor(htmlElement: HTMLElement, api: MultiSyncApi, metricLabels: any) {
        this.api = api;
        this.metricLabels = metricLabels;
        this.chart = create(htmlElement, XYChart);
        this.overlay = new ChartOverlay(this.chart);
        this.lineSeriesGroups = new LineSeriesGroups(new LineSeriesService());
        this.setChartOptions();
    }

    get _chart() {
        return this.chart;
    }

    get _overlay() {
        return this.overlay;
    }

    private setChartOptions() {
        this.chart.paddingRight = 20;
        this.chart.minHeight = 400;
        this.chart.responsive.enabled = false;
        this.chart.plotContainer.minHeight = 260;
        this.chart.cursor = new XYCursor();
        this.chart.cursor.maxTooltipDistance = 100;
        this.chart.cursor.behavior = 'zoomXY';
        this.chart.cursor.fullWidthLineX = true;
        this.chart.cursor.lineX.strokeWidth = 0;
        this.chart.cursor.lineX.fill = Chart.TOTAL_COLOR;
        this.chart.cursor.lineX.fillOpacity = 0.1;
        this.chart.cursor.lineY.disabled = true;
        this.chart.scrollbarX = new XYChartScrollbar();
        this.chart.scrollbarX.parent = this.chart.bottomAxesContainer;
        this.chart.scrollbarX.minHeight = 30;
        this.chart.scrollbarY = new XYChartScrollbar();
        this.chart.scrollbarY.minWidth = 30;
        this.chart.scrollbarX.background.fill = Chart.TOTAL_COLOR;
        this.chart.scrollbarX.background.fillOpacity = 0.2;
        this.chart.scrollbarY.background.fill = Chart.TOTAL_COLOR;
        this.chart.scrollbarY.background.fillOpacity = 0.2;
        this.chart.legend = new Legend();
        this.chart.legend.fontSize = 14;
        this.chart.legend.itemContainers.template.tooltipText = '{dataContext.dummyData.legendTooltipDescription}';
        const legendContainer = create('reporting-graph-legend', Container);
        legendContainer.width = percent(100);
        legendContainer.height = percent(100);
        legendContainer.htmlContainer.style.height = '39px';
        this.chart.events.on('datavalidated', resizeLegend.bind(this.chart));
        this.chart.events.on('maxsizechanged', resizeLegend.bind(this.chart));
        this.chart.legend.events.on('datavalidated', resizeLegend.bind(this.chart));
        this.chart.legend.events.on('maxsizechanged', resizeLegend.bind(this.chart));
        this.chart.legend.parent = legendContainer;
        this.customizeGrip(this.chart.scrollbarX.startGrip);
        this.customizeGrip(this.chart.scrollbarX.endGrip);
        this.customizeGrip(this.chart.scrollbarY.startGrip, 90);
        this.customizeGrip(this.chart.scrollbarY.endGrip, 90);
    }

    private customizeGrip(grip: ResizeButton, rotation: number = null) {
        grip.icon.disabled = true;
        grip.background.disabled = true;

        const img = grip.createChild(Circle);
        img.radius = 5;
        img.fill = Chart.TOTAL_COLOR;
        img.align = 'center';
        img.valign = 'middle';

        const line = grip.createChild(Rectangle);
        line.height = 30;
        line.width = 3;
        line.rotation = rotation;
        line.fill = Chart.TOTAL_COLOR;
        line.align = 'center';
        line.valign = 'middle';
    }

    clearLineSeries() {
        this.chart.series.clear();
        this.lineSeriesGroups.clearAll();
    }

    createYAxes() {
        this.chart.yAxes.clear();
        const valueAxis = this.chart.yAxes.push(new ValueAxis());
        valueAxis.renderer.grid.template.strokeOpacity = 0.05;
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.minHeight = 260;
        this.chart.cursor.yAxis = valueAxis;
        const valueAxisRight = this.chart.yAxes.push(new ValueAxis());
        valueAxisRight.renderer.grid.template.strokeOpacity = 0.05;
        valueAxisRight.renderer.opposite = true;
        valueAxisRight.syncWithAxis = valueAxis;
        valueAxisRight.cursorTooltipEnabled = false;
        valueAxisRight.renderer.minWidth = 35;
        valueAxisRight.disabled = true;
    }

    createXAxes(data: any, granularity: string): void {
        this.chart.xAxes.clear();
        const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
        categoryAxis.dataFields.category = 'category';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.cursorTooltipEnabled = false;
        categoryAxis.tooltip.zIndex = 500;
        categoryAxis.renderer.grid.template.strokeOpacity = 0.05;
        const firstMetric = Object.keys(data)[0];
        categoryAxis.data = data[firstMetric];
        this.chart.cursor.xAxis = categoryAxis;
        this.xAxesAction = new XAxesAction(categoryAxis);
        this.xAxesAction.runOn(granularity);
        if (data[`${LineSeriesOptionsFactory.TIME_SHIFT_PREFIX}${firstMetric}`]) {
            const timeShiftCategoryAxis = this.chart.xAxes.push(new CategoryAxis());
            timeShiftCategoryAxis.dataFields.category = 'originCategory';
            timeShiftCategoryAxis.renderer.grid.template.location = 0;
            timeShiftCategoryAxis.tooltip.zIndex = 500;
            this.chart.bottomAxesContainer.reverseOrder = true;
            timeShiftCategoryAxis.cursorTooltipEnabled = false;
            timeShiftCategoryAxis.opacity = 0.3;
            timeShiftCategoryAxis.renderer.grid.template.strokeOpacity = 0.05;
            timeShiftCategoryAxis.data = data[`${LineSeriesOptionsFactory.TIME_SHIFT_PREFIX}${firstMetric}`];
            this.xAxesAction2 = new XAxesAction(timeShiftCategoryAxis);
            this.xAxesAction2.runOn(granularity);
        }
    }

    update(graphParameters: GraphParameters) {
        this.showLoadingOverlay();
        this.api
            .getData(graphParameters)
            .then((response) => {
                this.clearLineSeries();
                this.createXAxes(response, graphParameters.granularity);
                this.createYAxes();
                this.addLineSeries(graphParameters, response);
                this.hideLoadingOverlay();
            })
            .catch((e) => {
                if (e instanceof ResponseCancelled) {
                    console.error('Response was cancelled');
                } else {
                    this.overlay.hide();
                    console.error(e, 'Error');
                }
            });
    }

    addLineSeries(graphParameters: GraphParameters, response: any) {
        const lineSeriesService = new LineSeriesService();
        const lineSeriesOptionsFactory = new LineSeriesOptionsFactory(graphParameters, response, this.metricLabels);
        Object.keys(response).forEach((identifier) => {
            const metric = graphParameters.metrics.find((currentMetric) => identifier.includes(currentMetric));
            if (!metric) {
                return;
            }
            const metricIndex = graphParameters.metrics.indexOf(metric);
            const yAxis = this.chart.yAxes.getIndex(metricIndex);
            const lineSeriesOptions = lineSeriesOptionsFactory.create(identifier, metric, metricIndex, yAxis);
            const lineSeries = lineSeriesService.create(lineSeriesOptions);
            this.lineSeriesGroups.add(lineSeriesOptions.getGroupName(), lineSeries, lineSeriesOptions.getColor());
        });
        this.chart.series.pushAll(this.lineSeriesGroups.getAll());
    }

    showLoadingOverlay() {
        this.overlay.show();
        this.chart.series.each((series) => {
            series.hide();
        });
    }

    hideLoadingOverlay() {
        this.overlay.hide();
    }

    dispose() {
        this.chart.dispose();
    }

    removeLineSeries(chartLine: ChartLine) {
        if (!chartLine) {
            return;
        }
        // show all chart lines before removing one line to prevent toggling bug
        // (caused by chart library assuming visible chart lines after chart update)
        this.chart.series.each((series) => {
            series.show();
        });
        const removedSeries = this.lineSeriesGroups.removeChartLine(chartLine);
        this.removeSeries(removedSeries);
    }

    removeTimeShift(): void {
        const removedSeries = this.lineSeriesGroups.removeSeries(LineSeriesOptionsFactory.TIME_SHIFT_PREFIX);
        this.removeSeries(removedSeries);
        const timeShiftXAxis = this.chart.xAxes.getIndex(1);
        if (timeShiftXAxis) {
            timeShiftXAxis.hide();
        }
    }

    removeMetric(metric: string) {
        const removedSeries = this.lineSeriesGroups.removeSeries(metric);
        this.removeSeries(removedSeries);
    }

    removeSeries(lineSeries: LineSeries[]) {
        lineSeries.forEach((series) => {
            this.chart.series.removeIndex(this.chart.series.indexOf(series));
        });
        setTimeout(resizeLegend.bind(this.chart), 200);
    }

    toggleTotalLegend() {
        const totalLineSeries = this.chart.series.getIndex(0);
        if (totalLineSeries.isHidden) {
            totalLineSeries.show();
        } else {
            totalLineSeries.hide();
        }
    }
}
