import { color } from '@amcharts/amcharts4/core';
import BrowserHistoryState from '@/store/BrowserHistoryState';
import ChartLine from '@/components/graph/ChartLine';
import CustomFilterTransformer from '@/store/compatibility/CustomFilterTransformer';

export default class BrowserHistoryStateConfig {
    applyURLState(): BrowserHistoryState {
        const url = new URL(window.location.toString());
        const searchParams = url.searchParams;
        const browserHistoryState = new BrowserHistoryState();
        this.applyDimensions(searchParams, browserHistoryState);
        this.applyMetrics(searchParams, browserHistoryState);
        this.applySorting(searchParams, browserHistoryState);
        this.applyPinning(searchParams, browserHistoryState);
        this.applyFilters(searchParams, browserHistoryState);
        this.applyFirstMetric(searchParams, browserHistoryState);
        this.applySecondMetric(searchParams, browserHistoryState);
        this.applyGranularity(searchParams, browserHistoryState);
        this.applyTimeShift(searchParams, browserHistoryState);
        this.applyChartLines(searchParams, browserHistoryState);
        this.applyDateFrom(searchParams, browserHistoryState);
        this.applyDateTo(searchParams, browserHistoryState);
        this.applyTimezone(searchParams, browserHistoryState);
        this.applyDateRangeSelection(searchParams, browserHistoryState);
        return browserHistoryState;
    }

    private applyDimensions(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('dimensions')) {
            browserHistoryState.dimensions = searchParams.getAll('dimensions');
        }
    }

    private applyMetrics(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('metrics')) {
            browserHistoryState.metrics = searchParams.getAll('metrics');
        }
    }

    private applySorting(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('sorting')) {
            browserHistoryState.sorting = searchParams.getAll('sorting').map((value) => JSON.parse(value));
        }
    }

    private applyPinning(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('pinning')) {
            browserHistoryState.pinning = searchParams.getAll('pinning').map((value) => JSON.parse(value));
        }
    }

    private applyFilters(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('filters')) {
            const parsedFilter = JSON.parse(searchParams.get('filters'));
            browserHistoryState.filters = new CustomFilterTransformer().transformFilter(parsedFilter);
        }
    }

    private applyFirstMetric(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('firstMetric')) {
            browserHistoryState.firstMetric = searchParams.get('firstMetric');
        }
    }

    private applySecondMetric(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('secondMetric')) {
            browserHistoryState.secondMetric = searchParams.get('secondMetric');
        }
    }

    private applyGranularity(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('granularity')) {
            browserHistoryState.granularity = searchParams.get('granularity');
        }
    }

    private applyTimeShift(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('timeShift')) {
            browserHistoryState.timeShift = searchParams.get('timeShift');
        }
    }

    private applyChartLines(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('chartLines')) {
            browserHistoryState.chartLines = searchParams.getAll('chartLines').map((value) => {
                const urlChartLine = JSON.parse(value);
                const chartLine = ChartLine.of(urlChartLine.value, urlChartLine.description);
                chartLine.color = color(urlChartLine.color);
                return chartLine;
            });
        }
    }

    private applyDateFrom(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('dateFrom')) {
            browserHistoryState.dateFrom = searchParams.get('dateFrom');
        }
    }

    private applyDateTo(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('dateTo')) {
            browserHistoryState.dateTo = searchParams.get('dateTo');
        }
    }

    private applyTimezone(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('timezone')) {
            browserHistoryState.timezone = searchParams.get('timezone');
        }
    }

    private applyDateRangeSelection(searchParams: URLSearchParams, browserHistoryState: BrowserHistoryState): void {
        if (searchParams.has('dateRangeSelection')) {
            browserHistoryState.dateRangeSelection = searchParams.get('dateRangeSelection');
        }
    }
}
