<template>
    <div class="ag-filter-body-wrapper ag-simple-filter-body-wrapper">
        <div class="ag-filter-wrapper">
            <div v-if="filterValues.conditions.length > 1" class="ag-filter-condition">
                <div
                    class="ag-wrapper ag-input-wrapper ag-radio-button-input-wrapper"
                    :class="{ 'ag-checked': filterValues.operator === 'AND' }"
                >
                    <input
                        class="ag-input-field-input ag-radio-button-input"
                        type="radio"
                        value="AND"
                        :checked="filterValues.operator === 'AND'"
                        @click="assignFilterValueOperator('AND')"
                    />
                </div>
                <div class="ag-input-field-label ag-label ag-radio-button-label">AND</div>
                <div
                    class="ag-wrapper ag-input-wrapper ag-radio-button-input-wrapper or"
                    :class="{ 'ag-checked': filterValues.operator === 'OR' }"
                >
                    <input
                        class="ag-input-field-input ag-radio-button-input"
                        type="radio"
                        value="OR"
                        :checked="filterValues.operator === 'OR'"
                        @click="assignFilterValueOperator('OR')"
                    />
                </div>
                <div class="ag-input-field-label ag-label ag-radio-button-label">OR</div>
            </div>
            <div class="ag-text-field-input-wrapper">
                <CustomDimensionFilterCondition
                    v-for="(condition, index) of filterValues.conditions"
                    :key="getKey(index)"
                    :filter-options="['equals', 'not equals']"
                    :filter-type="condition.filterType"
                    :type="condition.type"
                    :filter="condition.filter"
                    @filterConditionUpdated="onFilterUpdated($event, index)"
                />
            </div>
            <div class="ag-side-bar">
                <div class="ag-filter-apply-panel">
                    <button class="ag-standard-button ag-filter-apply-panel-button" type="button" @click="resetFilter">
                        Reset
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomDimensionFilterCondition from '@/components/grid/CustomDimensionFilterCondition';
import GridFilterService from '@/components/grid/GridFilterService';

function getDefaultCondition() {
    return {
        filterType: 'text',
        type: 'equals',
        filter: '',
    };
}

function getDefaultFilter() {
    return {
        filterType: 'text',
        operator: 'OR',
        conditions: [getDefaultCondition()],
    };
}

const MAX_INPUT_FIELDS = 10;
const gridFilterService = new GridFilterService();
export default {
    name: 'CustomDimensionFilter',
    components: {
        CustomDimensionFilterCondition,
    },
    data: () => ({
        filterValues: { ...getDefaultFilter() },
    }),
    methods: {
        isFilterActive() {
            return this.filterValues.conditions.map((condition) => condition.filter).filter((value) => value).length > 0;
        },
        getModel() {
            if (!this.isFilterActive()) {
                return null;
            }
            return this.filterValues;
        },
        setModel(model) {
            if (model) {
                this.filterValues = model;
            } else {
                this.resetToEmptyModel();
            }
        },
        resetFilter() {
            const columnName = this.params.colDef.field;
            const appliedFilterModel = this.getFilterFromStore();
            // eslint-disable-next-line no-prototype-builtins
            const isFilteredForColumn = appliedFilterModel && appliedFilterModel.hasOwnProperty(columnName);
            this.resetToEmptyModel();
            if (isFilteredForColumn) {
                gridFilterService.applyAllFilters(this.params.api);
            }
        },
        resetToEmptyModel() {
            this.filterValues = getDefaultFilter();
        },
        addConditionInput() {
            this.filterValues.conditions.push({
                ...getDefaultCondition(),
            });
        },
        onFilterUpdated(event, index) {
            this.filterValues.conditions[index] = event;
            this.removeUnusedInputFields();
            if (!this.hasEmptyConditionInput() && this.filterValues.conditions.length < MAX_INPUT_FIELDS) {
                this.addConditionInput();
            }
        },
        removeUnusedInputFields() {
            this.filterValues.conditions = this.filterValues.conditions.filter(
                (condition, index) => index >= this.filterValues.conditions.length - 1 || condition.filter !== '',
            );
        },
        hasEmptyConditionInput() {
            return this.filterValues.conditions.map((condition) => condition.filter.length).includes(0);
        },
        getKey(index) {
            return `${this.filterValues.conditions[index]}-${this.filterValues.conditions[index].type}-${index}`;
        },
        assignFilterValueOperator(operator) {
            this.filterValues.operator = operator;
        },
        getFilterFromStore() {
            const browserHistoryState = this.$store.getters['historyStore/historyState'];
            return browserHistoryState.filters;
        },
    },
};
</script>
<style lang="scss" scoped>
$radioButtonSize: 16px;

.ag-input-field-label.ag-label.ag-radio-button-label input {
    position: relative;
    width: $radioButtonSize;
    height: $radioButtonSize;
    top: 3px;
}

.ag-filter-condition {
    margin-bottom: 7px;
}

.ag-filter-apply-panel {
    border: none;
    overflow: visible;
    padding: 6px 0 0;
}

.ag-side-bar {
    border-top: 1px solid #bdc3c7;
}

.ag-radio-button-label {
    margin: 0 4px;
}

.ag-wrapper.ag-input-wrapper.ag-radio-button-input-wrapper {
    &.or {
        margin-left: 8px;
    }
}
</style>
