import ChartLine from '@/components/graph/ChartLine';
import Dimension from '@/components/grid/Dimension';
import { SlicerChartLineExtractor } from '@/components/grid/SlicerChartLineExtractor';

export default class ChartLineTransformer {
    private readonly slicerChartLineExtractor: SlicerChartLineExtractor;

    constructor(slicerChartLineExtractor: SlicerChartLineExtractor) {
        this.slicerChartLineExtractor = slicerChartLineExtractor;
    }

    transform(rowData: any) {
        return Object.entries(rowData)
            .filter(([, columnValue]) => columnValue instanceof Dimension)
            .map((composedDimension) => composedDimension[1] as Dimension)
            .reduce((accumulator: ChartLine, dimension: Dimension) => {
                const extractedChartLine = this.slicerChartLineExtractor.extract(dimension);
                return accumulator.merge(extractedChartLine);
            }, new ChartLine());
    }
}
