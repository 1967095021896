import { ColumnApi, IServerSideGetRowsParams } from 'ag-grid-community';
import IGlobalFilter from '@/components/grid/IGlobalFilter';
import IRequestParameterTransformer from '@/components/grid/api/IRequestParameterTransformer';
import IowRequestParameters from '@/components/grid/api/iow/request/IowRequestParameters';
import IowFiltersTransformer from '@/components/grid/api/iow/request/IowFiltersTransformer';
import DimensionFilter from '@/components/grid/api/DimensionFilter';
import MetricFilter from '@/components/grid/api/MetricFilter';
import IowMetricFilterTemplateGenerator from '@/components/grid/api/iow/request/IowMetricFilterTemplateGenerator';
import ColumnDefinitionService from '../../../ColumnDefinitionService';

export default class IowRequestParameterTransformer implements IRequestParameterTransformer {
    private readonly iowFiltersTransformer: IowFiltersTransformer;

    private readonly dimensionFilter: DimensionFilter;

    private readonly metricFilter: MetricFilter;

    private readonly iowMetricFilterTemplateGenerator: IowMetricFilterTemplateGenerator;

    private readonly columnDefinitionService: ColumnDefinitionService;

    constructor(
        iowFiltersTransformer: IowFiltersTransformer,
        dimensionFilter: DimensionFilter,
        metricFilter: MetricFilter,
        iowMetricFilterTemplateGenerator: IowMetricFilterTemplateGenerator,
        columnDefinitionService: ColumnDefinitionService,
    ) {
        this.iowFiltersTransformer = iowFiltersTransformer;
        this.dimensionFilter = dimensionFilter;
        this.metricFilter = metricFilter;
        this.iowMetricFilterTemplateGenerator = iowMetricFilterTemplateGenerator;
        this.columnDefinitionService = columnDefinitionService;
    }

    transform(
        gridParams: IServerSideGetRowsParams & { gridOptionsService: { columnApi: ColumnApi } },
        globalFilter: IGlobalFilter,
    ) {
        const iowRequestParameters = new IowRequestParameters();
        iowRequestParameters.setLimitations(gridParams.request.startRow, gridParams.request.endRow);
        iowRequestParameters.setGroupKeyFilters(gridParams.request.rowGroupCols, gridParams.request.groupKeys);
        const dimensionFilters = this.iowFiltersTransformer.transform(gridParams.request.filterModel, this.dimensionFilter);
        iowRequestParameters.setFilters(dimensionFilters);
        const metricsFilters = this.iowFiltersTransformer.transform(gridParams.request.filterModel, this.metricFilter);
        iowRequestParameters.setMetricFilters(metricsFilters);
        iowRequestParameters.setMetricFilterTemplate(this.iowMetricFilterTemplateGenerator.generate(metricsFilters));
        iowRequestParameters.setOrders(gridParams.request.sortModel, gridParams.request.rowGroupCols);
        iowRequestParameters.startDate = globalFilter.getStartDate();
        iowRequestParameters.endDate = globalFilter.getEndDate();
        iowRequestParameters.timezone = globalFilter.getTimezone();
        const columnApi = gridParams.columnApi ? gridParams.columnApi : gridParams.gridOptionsService.columnApi;
        iowRequestParameters.setDimensions(this.columnDefinitionService.getActiveDimensions(columnApi));
        iowRequestParameters.setMetrics(this.columnDefinitionService.getActiveMetrics(columnApi));
        return iowRequestParameters;
    }
}
