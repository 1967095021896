import IowResponseRow from '@/components/grid/api/iow/response/IowResponseRow';

export default class IowMetricsTransformer {
    transform(data: IowResponseRow[]) {
        return data.reduce((accumulator, column) => {
            accumulator[column.name] = column.value;
            return accumulator;
        }, {});
    }
}
