import { Color } from '@amcharts/amcharts4/.internal/core/utils/Color';
import { Axis } from '@amcharts/amcharts4/charts';

export default class LineSeriesOptions {
    private readonly data: any;

    private readonly groupName: string;

    private readonly identifier: string;

    private readonly metric: string;

    private readonly metricIndex: number;

    private readonly metricLabel: string;

    private readonly granularity: string;

    private readonly yAxis: Axis;

    private readonly color: Color;

    private readonly opacity: number;

    constructor({ data, groupName, identifier, metric, metricIndex, metricLabel, granularity, yAxis, color, opacity }) {
        this.data = data;
        this.groupName = groupName;
        this.identifier = identifier;
        this.metric = metric;
        this.metricIndex = metricIndex;
        this.metricLabel = metricLabel;
        this.granularity = granularity;
        this.yAxis = yAxis;
        this.color = color;
        this.opacity = opacity;
    }

    getData(): any {
        return this.data;
    }

    getGroupName(): string {
        return this.groupName;
    }

    getIdentifier(): string {
        return this.identifier;
    }

    getMetric(): string {
        return this.metric;
    }

    getMetricIndex(): number {
        return this.metricIndex;
    }

    getMetricLabel(): string {
        return this.metricLabel;
    }

    getGranularity(): string {
        return this.granularity;
    }

    getYAxis(): Axis {
        return this.yAxis;
    }

    getColor(): Color {
        return this.color;
    }

    getOpacity(): number {
        return this.opacity;
    }
}
