export const granularities: any[] = [
    {
        label: 'Hour',
        value: 'granularity_hour',
    },
    {
        label: 'Day',
        value: 'granularity_day',
    },
    {
        label: 'Week',
        value: 'granularity_week',
    },
    {
        label: 'Month',
        value: 'granularity_month',
    },
    {
        label: 'Quarter',
        value: 'granularity_quarter',
    },
    {
        label: 'Year',
        value: 'granularity_year',
    },
];
