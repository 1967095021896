import { ColDef } from 'ag-grid-community';
import BrowserHistoryState from '@/store/BrowserHistoryState';
import SortColumn, { DIRECTION } from '@/store/SortColumn';
import PinnedColumn, { ORIENTATION } from '@/store/PinnedColumn';

export default class GridColumnConfig {
    apply(browserHistoryState: BrowserHistoryState, columnDefinition: ColDef[]): ColDef[] {
        return columnDefinition
            .sort((colDefA, colDefB) => {
                const indexOfA = browserHistoryState.dimensions.indexOf(colDefA.field);
                const indexOfB = browserHistoryState.dimensions.indexOf(colDefB.field);
                return this.getSortOrder(indexOfA, indexOfB);
            })
            .sort((colDefA, colDefB) => {
                const indexOfA = browserHistoryState.metrics.indexOf(colDefA.field);
                const indexOfB = browserHistoryState.metrics.indexOf(colDefB.field);
                return this.getSortOrder(indexOfA, indexOfB);
            })
            .map(this.applyVisibility(browserHistoryState))
            .map(this.applySorting(browserHistoryState))
            .map(this.applyPinning(browserHistoryState));
    }

    private getSortOrder(indexOfA, indexOfB) {
        if (indexOfB === -1 && indexOfA === -1) {
            return 0;
        }
        if (indexOfA === -1 && indexOfB > indexOfA) {
            return 1;
        }
        if (indexOfB === -1 && indexOfA >= 0) {
            return -1;
        }
        return indexOfA - indexOfB;
    }

    private applySorting(browserHistoryState: BrowserHistoryState) {
        return (colDef) => {
            const sortedColumns: SortColumn = browserHistoryState.sorting.find((sortColumn) => sortColumn.name === colDef.field);
            if (sortedColumns !== undefined) {
                colDef.sort = DIRECTION[sortedColumns.direction];
                colDef.sortIndex = browserHistoryState.sorting.findIndex((sortColumn) => sortColumn.name === colDef.field);
            }
            return colDef;
        };
    }

    private applyVisibility(browserHistoryState: BrowserHistoryState) {
        return (colDef) => {
            const isVisible = Boolean(
                [...browserHistoryState.dimensions, ...browserHistoryState.metrics].filter((colId) => colId === colDef.field)
                    .length,
            );

            colDef.hide = !isVisible;
            return colDef;
        };
    }

    private applyPinning(browserHistoryState: BrowserHistoryState) {
        return (colDef) => {
            const pinnedColumn: PinnedColumn = browserHistoryState.pinning.find((column) => column.name === colDef.field);
            if (pinnedColumn !== undefined) {
                colDef.pinned = ORIENTATION[pinnedColumn.orientation];
            }

            return colDef;
        };
    }
}
