import { CategoryAxis } from '@amcharts/amcharts4/charts';
import IGranularityAction from '@/components/graph/manipulation/granularity/IGranularityAction';

export default class XAxesAction implements IGranularityAction {
    private readonly modifier;

    private readonly axes: CategoryAxis;

    constructor(axes: CategoryAxis) {
        this.axes = axes;
        this.modifier = {
            granularity_hour: () => this.granularityHour(),
            granularity_day: () => this.granularityDay(),
            granularity_month: () => this.granularityMonth(),
        };
    }

    runOn(granularity: string): void {
        if (this.modifier[granularity]) {
            this.modifier[granularity]();
            return;
        }
        this.granularityDefault();
    }

    granularityDefault(): void {
        this.axes.renderer.labels.template.adapter.add('text', () => '{category}');
        this.axes.adapter.add('getTooltipText', () => `{category}`);
    }

    private granularityHour(): void {
        this.axes.dateFormatter.inputDateFormat = 'YYYY-MM-dd HH:mm';
        this.axes.renderer.labels.template.adapter.add('text', () => "{category.formatDate('MMM dt')}");
        this.axes.adapter.add('getTooltipText', () => `{category.formatDate('YYYY MMM dt HH:mm')}`);
    }

    private granularityDay(): void {
        this.axes.renderer.labels.template.adapter.add('text', () => "{category.formatDate('MMM dt')}");
        this.axes.adapter.add('getTooltipText', () => `{category.formatDate('YYYY MMM dt')}`);
    }

    private granularityMonth(): void {
        this.axes.renderer.labels.template.adapter.add('text', () => "{category.formatDate('MMM YYYY')}");
        this.axes.adapter.add('getTooltipText', () => `{category.formatDate('MMM YYYY')}`);
    }
}
