import { Expose, Type } from 'class-transformer';
import 'es6-shim';
import 'reflect-metadata';
import IowResponseRow from '@/components/graph/api/iow/response/IowResponseRow';

export default class IowResponse {
    @Expose()
    status: string;

    @Expose()
    @Type(() => IowResponseRow)
    rows: IowResponseRow[];

    @Expose()
    mappings?: {};
}
