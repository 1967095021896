import { ColDef } from 'ag-grid-community';
import ApiDatasource from '@/components/grid/ApiDatasource';
import ComponentFactory from '@/components/grid/ComponentFactory';
import IowComponentFactory from '@/components/grid/api/iow/IowComponentFactory';
import VueStoreFilter from '@/components/grid/VueStoreFilter';
import graphLineColumn from '@/components/grid/graphLineColumn';
import GridColumnConfig from '@/components/grid/GridColumnConfig';
import store from '@/store';
import URLWrapper from '@/store/URLWrapper';

const iowComponentFactory: ComponentFactory = new IowComponentFactory();
const api = iowComponentFactory.createSyncApi(new VueStoreFilter());
const defaultDimensions: ColDef[] = iowComponentFactory.createDimensions();
const defaultMetrics: ColDef[] = iowComponentFactory.createMetrics();
const gridEvents = iowComponentFactory.createGridEvents();
const apiDatasource = new ApiDatasource(api);

const urlWrapper: URLWrapper = store.getters['historyStore/urlWrapper'];
store.commit('setDefaultDimensions', [...defaultDimensions]);
store.commit('setDefaultMetrics', [...defaultMetrics]);
let dimensions = [...defaultDimensions];
let metrics = [...defaultMetrics];
if (urlWrapper.hasParameters()) {
    const gridColumnConfig = new GridColumnConfig();
    const browserHistoryState = store.getters['historyStore/historyState'];
    dimensions = gridColumnConfig.apply(browserHistoryState, defaultDimensions);
    metrics = gridColumnConfig.apply(browserHistoryState, defaultMetrics);
}
const gridOptions = {
    columnDefs: [graphLineColumn, ...dimensions, ...metrics],
    defaultColDef: {
        flex: 1,
        minWidth: 150,
        resizable: true,
        sortable: true,
        filter: false,
    },
    rowModelType: 'serverSide',
    suppressServerSideInfiniteScroll: 'partial',
    suppressExcelExport: true,
    suppressCsvExport: true,
    cacheBlockSize: 100,
    serverSideDatasource: apiDatasource,
    groupDisplayType: 'multipleColumns',
    suppressFieldDotNotation: true,
    maxConcurrentDatasourceRequests: 1,
    rowBuffer: 0,
    animateRows: true,
    fullWidthCellRenderer: (params) => {
        let totalRows = 0;
        if (params && params.data && params.data.totalRows) {
            totalRows = params.data.totalRows;
        }
        const eDiv = document.createElement('div');
        eDiv.innerHTML = `<div class="total-row">Total Rows: ${totalRows}</div>`;
        return eDiv.firstChild;
    },
    rowClassRules: {
        'first-row': (params) => {
            const previousRow = params.api.getDisplayedRowAtIndex(params.rowIndex - 1);
            const firstDimension = defaultDimensions[0].colId;
            if (previousRow && previousRow.data && params.data && params.data[firstDimension]) {
                return !params.data[firstDimension].compare(previousRow.data[firstDimension]);
            }
            return params.rowIndex === 0;
        },
    },
    sideBar: {
        toolPanels: [
            {
                id: 'dimensions',
                labelDefault: 'Dimensions',
                labelKey: 'dimensions',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                    suppressSideButtons: true,
                    suppressColumnSelectAll: true,
                    suppressSyncLayoutWithGrid: true,
                },
            },
            {
                id: 'metrics',
                labelDefault: 'Metrics',
                labelKey: 'metrics',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                    suppressSideButtons: true,
                    suppressSyncLayoutWithGrid: false,
                },
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
        ],
    },
    ...gridEvents,
};

export default gridOptions;
