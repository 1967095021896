import IApiRequest from '@/components/grid/api/IApiRequest';
import IApi from '@/components/grid/api/IApi';
import IGlobalFilter from '@/components/grid/IGlobalFilter';
import IRequestParameterTransformer from '@/components/graph/api/IRequestParameterTransformer';
import MultiSyncApi from '@/components/graph/api/MultiSyncApi';
import IResponseTransformer from '@/components/graph/api/IResponseTransformer';
import IMultiResponseTransformer from '@/components/graph/api/IMultiResponseTransformer';

export default abstract class GraphComponentFactory {
    abstract createApiRequest(): IApiRequest;

    abstract createRequestParameterTransformer(): IRequestParameterTransformer;

    abstract createResponseTransformer(): IResponseTransformer;

    abstract createMultiResponseTransformer(): IMultiResponseTransformer;

    createMultiSyncApi(globalFilter: IGlobalFilter): IApi {
        const apiRequest = this.createApiRequest();
        const requestTransformer = this.createRequestParameterTransformer();
        const responseTransformer = this.createResponseTransformer();
        const multiResponseTransformer = this.createMultiResponseTransformer();

        return new MultiSyncApi(apiRequest, requestTransformer, responseTransformer, multiResponseTransformer, globalFilter);
    }
}
