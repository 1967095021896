export default abstract class ResponseCollection {
    protected responses = [];

    abstract getAll(): any[];

    abstract push(response: any): void;

    isEmpty(): boolean {
        return !this.responses.length;
    }
}
