import DimensionIdTransformer from '@/components/grid/api/iow/response/DimensionIdTransformer';
import DimensionDescriptionTransformer from '@/components/grid/api/iow/response/DimensionDescriptionTransformer';
import { dimensionsWithoutIdVisibility } from '@/components/grid/api/iow/defaultDimensions';
import Dimension from '@/components/grid/Dimension';

export default class IowDimensionsTransformer {
    transform(name, mapping, groups: string[] = []) {
        return groups.reduce((accumulator, columnName, index) => {
            const dimensionIdTransformer = new DimensionIdTransformer();
            const dimensionDescriptionTransformer = new DimensionDescriptionTransformer();
            const dimensionId = dimensionIdTransformer.transform(name, index);
            const dimensionDescription = dimensionDescriptionTransformer.transform(mapping, index);
            if (dimensionsWithoutIdVisibility.includes(columnName) && dimensionDescription) {
                accumulator[columnName] = Dimension.of(null, dimensionDescription);
            } else {
                accumulator[columnName] = Dimension.of(dimensionId, dimensionDescription);
            }
            return accumulator;
        }, {});
    }
}
