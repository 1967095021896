import IowFilterModelTransformer from '@/components/grid/api/iow/request/IowFilterModelTransformer';
import IowDimensionFilter from '@/components/grid/api/iow/request/IowDimensionFilter';
import IowMultiFilterModelTransformer from '@/components/grid/api/iow/request/IowMultiFilterModelTransformer';
import IColumnFilter from '@/components/grid/api/IColumnFilter';

export default class IowFiltersTransformer {
    private readonly iowFilterModelTransformer: IowFilterModelTransformer;

    private readonly iowMultiFilterModelTransformer: IowMultiFilterModelTransformer;

    constructor(
        iowFilterModelTransformer: IowFilterModelTransformer,
        iowMultiFilterModelTransformer: IowMultiFilterModelTransformer,
    ) {
        this.iowFilterModelTransformer = iowFilterModelTransformer;
        this.iowMultiFilterModelTransformer = iowMultiFilterModelTransformer;
    }

    transform(gridFilterModel: any, columnFilter: IColumnFilter): IowDimensionFilter[] {
        if (!gridFilterModel) {
            return [];
        }
        const filteredFilterModel = columnFilter.filter(gridFilterModel);
        return Object.keys(filteredFilterModel).flatMap((columnKey) => {
            const filterModel = filteredFilterModel[columnKey];
            if (this.isMultiFilterModel(filterModel)) {
                return this.iowMultiFilterModelTransformer.transform(columnKey, filterModel.filterModels);
            }

            return this.iowFilterModelTransformer.transform(columnKey, filterModel);
        });
    }

    private isMultiFilterModel(filter): boolean {
        // eslint-disable-next-line no-prototype-builtins
        return filter.hasOwnProperty('filterModels');
    }
}
