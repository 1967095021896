<template>
    <div class="side-navigation">
        <VProgressLinear v-if="isLoading" indeterminate />
        <SrNavigationBar v-if="!isLoading" :items="sideNavigationViewModel.getNavigationItems()" />
    </div>
</template>

<script>
import { SrNavigationBar } from '@ads/design-system';
import SideNavigationViewModel from '@/components/navigation/SideNavigationViewModel';
import StaticDashboardService from '@/dashboards/StaticDashboardService';
import DynamicDashboardService from '@/dashboards/DynamicDashboardService';
import RoutingService from '@/routes/RoutingService';

export default {
    name: 'SideNavigation',
    components: { SrNavigationBar },
    data() {
        return {
            sideNavigationViewModel: new SideNavigationViewModel(new StaticDashboardService(), new DynamicDashboardService()),
            routingService: new RoutingService(this.$router),
            isLoading: true,
        };
    },
    async mounted() {
        const dynamicDashboards = await this.sideNavigationViewModel.loadDynamicDashboards();
        await this.routingService.addDynamicDashboardRoutes(dynamicDashboards);
        this.isLoading = false;
    },
};
</script>
