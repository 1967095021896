import ChartLine from '@/components/graph/ChartLine';
import IChartLineCollection from '@/components/graph/IChartLineCollection';
import CustomChartLineCollection from '@/components/graph/CustomChartLineCollection';

export default class LimitedFifoChartLineCollection extends CustomChartLineCollection {
    private readonly maxSize: number;

    constructor(chartLineCollection: IChartLineCollection, maxSize: number) {
        super(chartLineCollection);
        this.maxSize = maxSize;
    }

    add(chartLine: ChartLine): void {
        const allChartLines = this.chartLineCollection.getAll();
        if (allChartLines.length === this.maxSize) {
            this.chartLineCollection.remove(allChartLines.shift());
        }

        this.chartLineCollection.add(chartLine);
    }
}
