import ChartLine from '@/components/graph/ChartLine';
import IChartLineCollection from '@/components/graph/IChartLineCollection';

export default abstract class ChartLineCollectionDecorator implements IChartLineCollection {
    protected chartLines: ChartLine[] = [];

    protected addEvent: CallableFunction;

    protected removeEvent: CallableFunction;

    watchAdd(addEvent: CallableFunction): void {
        this.addEvent = addEvent;
    }

    watchRemove(removeEvent: CallableFunction): void {
        this.removeEvent = removeEvent;
    }

    get(chartLine): ChartLine {
        const filteredChartLine = this.chartLines.filter((currentChartLine) => currentChartLine.isEqual(chartLine));
        if (!filteredChartLine.length) {
            return null;
        }
        return filteredChartLine[0];
    }

    getAll(): ChartLine[] {
        return this.chartLines;
    }

    indexOf(chartLine: ChartLine): number {
        let chartLineIndex = -1;
        this.chartLines.forEach((currentChartLine, index) => {
            if (currentChartLine.isEqual(chartLine)) {
                chartLineIndex = index;
            }
        });
        return chartLineIndex;
    }

    add(chartLine: ChartLine): void {
        if (chartLine && !this.contains(chartLine)) {
            this.chartLines.push(chartLine);
            if (this.addEvent) {
                this.addEvent();
            }
        }
    }

    remove(chartLine: ChartLine): void {
        [...this.chartLines].forEach((currentChartLine, index) => {
            if (currentChartLine.isEqual(chartLine)) {
                this.chartLines.splice(index, 1);
                if (this.removeEvent) {
                    this.removeEvent();
                }
            }
        });
    }

    removeAll(): void {
        this.chartLines.splice(0, this.chartLines.length);
        if (this.removeEvent) {
            this.removeEvent();
        }
    }

    contains(chartLine: ChartLine): boolean {
        return this.chartLines.some((currentChartLine) => currentChartLine.isEqual(chartLine));
    }

    size(): number {
        return this.chartLines.length;
    }
}
