import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { classToPlain } from 'class-transformer';
import IApiRequest from '@/components/grid/api/IApiRequest';
import IowRequestParameters from '@/components/grid/api/iow/request/IowRequestParameters';
import { API_URL } from '@/config';

export default class IowExportRequest implements IApiRequest {
    fetch(requestParams: IowRequestParameters): Promise<string> {
        const requestService = new RequestService({
            baseUrl: API_URL,
        });
        const options: IRequestOptions = {
            domain: 'reporting-studio',
            resource: 'download-report',
            action: ACTION_NAME.READ,
        };

        return requestService.post<object, string>(
            'download-report',
            classToPlain(requestParams, { groups: ['export'] }),
            options,
        );
    }
}
