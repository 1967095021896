import IApiRequest from '@/components/grid/api/IApiRequest';
import IowExportRequestTransformer from '@/components/grid/api/iow/request/IowExportRequestTransformer';
import IExportTransformer from '@/components/grid/api/IExportTransformer';
import IowExportRequest from '@/components/grid/api/iow/request/IowExportRequest';
import ExportComponentFactory from '@/components/grid/ExportComponentFactory';

export default class IowExportComponentFactory extends ExportComponentFactory {
    createApiRequest(): IApiRequest {
        return new IowExportRequest();
    }

    createExportRequestTransformer(): IExportTransformer {
        return new IowExportRequestTransformer();
    }
}
