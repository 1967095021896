import IowDimensionFilter from '@/components/grid/api/iow/request/IowDimensionFilter';

export default class IowMetricFilterTemplateGenerator {
    generate(rangeFilters: IowDimensionFilter[]): string {
        const columnCount = rangeFilters.reduce((accumulator, filter) => {
            if (!accumulator[filter.name]) {
                accumulator[filter.name] = 0;
            }
            accumulator[filter.name] += 1;
            return accumulator;
        }, {});

        const columnTemplates = Object.keys(columnCount).reduce((accumulator, column) => {
            const template = Array(Number(columnCount[column]) / 2).fill('x AND x');
            accumulator.push(`(${template.join(' OR ')})`);
            return accumulator;
        }, []);

        return columnTemplates.join(' AND ');
    }
}
