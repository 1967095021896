import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue';
import App from './App.vue';
import DesignSystem, {vuetify} from '@ads/design-system';
import '@/scss/main.scss';
import routes from '@/routes';
import store from '@/store';
import VueRouter from 'vue-router'
import singleSpaVue from 'single-spa-vue';
import {LicenseManager} from 'ag-grid-enterprise';
import * as am4core from "@amcharts/amcharts4/core";
import './vuetify-loader.conf.js';

am4core.addLicense(process.env.VUE_APP_CHART_LICENSE);
LicenseManager.setLicenseKey(process.env.VUE_APP_GRID_LICENSE);
Vue.use(DesignSystem);
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: '/reporting',
    routes
});

const vueLifeCycles = singleSpaVue({
    Vue,
    appOptions: {
        vuetify,
        render: h => h(App),
        router,
        store,
        el: '#microfrontend-application-container'
    }
});

export const bootstrap = vueLifeCycles.bootstrap;
export const mount = vueLifeCycles.mount;
export const unmount = vueLifeCycles.unmount;
