<template>
    <div>
        <div class="select-wrapper">
            <select class="sr-grid-filter-dropdown" @change="onOptionChanged($event)">
                <option
                    v-for="option of filterOptions"
                    :key="option"
                    class="sr-grid-filter-dropdown__option"
                    :value="option"
                    :selected="selectedType === option"
                >
                    {{ option }}
                </option>
            </select>
            <div ref="eIcon" class="ag-picker-field-icon" aria-hidden="true">
                <span class="ag-icon ag-icon-small-down" role="presentation" />
            </div>
        </div>
        <label>
            <input v-model="filterValue" type="text" class="ag-input-field-input ag-text-field-input" placeholder="Filter..." />
        </label>
    </div>
</template>
<script>
export default {
    name: 'CustomDimensionFilterCondition',
    props: {
        filterOptions: {
            type: Array,
            required: true,
        },
        filterType: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        filter: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        filterValue: '',
        selectedType: '',
    }),
    watch: {
        filter: {
            handler(val) {
                if (val !== this.filterValue) {
                    this.filterValue = val;
                }
            },
        },
        type: {
            handler(val) {
                if (val !== this.selectedType) {
                    this.selectedType = val;
                }
            },
        },
    },
    mounted() {
        this.filterValue = this.filter;
        this.selectedType = this.type;
        // We start watching after initializing values to avoid triggering
        // filterConditionUpdated-Events which could result in deleting FilterConditions.
        this.initWatchers();
    },
    methods: {
        onOptionChanged(event) {
            this.selectedType = event.target.value;
        },
        emitChangedFilterCondition() {
            this.$emit('filterConditionUpdated', {
                filterType: this.filterType,
                type: this.selectedType,
                filter: this.filterValue,
            });
        },
        initWatchers() {
            this.$watch('filterValue', () => {
                this.emitChangedFilterCondition();
            });
            this.$watch('selectedType', () => {
                this.emitChangedFilterCondition();
            });
        },
    },
};
</script>

<style scoped lang="scss">
select.sr-grid-filter-dropdown {
    position: relative;
    width: 100%;
    background: transparent;
    border: 1px solid rgb(189, 195, 199);
    padding: 4px;
    margin-bottom: 4px;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;

    &:focus,
    &:focus-visible {
        box-shadow: 0 0 2px 1px #719ece;
        outline: none;
    }
}

.select-wrapper {
    position: relative;
    background: white;

    .ag-picker-field-icon {
        position: absolute;
        display: block;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.ag-input-field-input.ag-text-field-input {
    margin-bottom: 12px;
}
</style>
